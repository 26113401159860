import { Vue, Component, Emit } from 'vue-property-decorator';
import Field from '@/../bower_components/teknisavuecomponents/assets/interfaces/Field';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';

import TkForm from '@/../bower_components/teknisavuecomponents/components/TkForm.vue';
import PerfilController from '@/assets/ts/controller/PerfilController';
import ToolbarAction from '@/../bower_components/teknisavuecomponents/assets/interfaces/ToolbarAction';
import MeuPerfilTab from '@/components/meu-perfil/MeuPerfilTab/MeuPerfilTab.vue';

@Component({
  name: 'DadosAdicionais',
  components: {
    TkForm,
    MeuPerfilTab,
  },
})
class DadosAdicionais extends Vue {
  // #region [ PROPS ]
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('intersection')
  public emitIntersection() {}
  // #endregion

  // #region [ DATA ]
  public row: any = {};
  public backup: any = {};

  private loading = {
    reload: false,
    save: false,
  };

  public actions: ToolbarAction[] = [
    {
      name: 'save',
      label: 'Salvar',
      mode: 'edit',
      color: 'green',
      icon: 'mdi-content-save',
      onClick: this.save,
    },
    {
      name: 'cancel',
      label: 'Desfazer alterações',
      mode: 'edit',
      color: 'red',
      icon: 'mdi-close-circle',
      onClick: this.cancel,
    },
  ];
  // #endregion

  // #region [ COMPUTED ]
  public get isLoading(): boolean {
    return Util.anyTrue(Object.values(this.loading));
  }

  public get fields(): Field[] {
    return [
      {
        name: 'DSAREAINTERESSE',
        label: 'Área de Interesse',
        type: 'text',
        size: 12,
        md: 6,
        required: true,
      },
      {
        name: 'NRCARTHABPES',
        label: 'Nr. CNH',
        type: 'text',
        size: 12,
        md: 4,
      },
      {
        name: 'DSCATEGHABCART',
        label: 'Categoria CNH',
        type: 'text',
        size: 12,
        md: 2,
      },
      {
        name: 'DTVALHABCARTPES',
        label: 'Validade CNH',
        type: 'date',
        hideDatePicker: true,
        size: 12,
        md: 3,
      },
      {
        name: 'DSAPRESENTACAO',
        label: 'Apresentação do Candidato',
        type: 'textarea',
        size: 12,
        md: 12,
        rows: 4,
        required: true,
      },
      {
        name: 'DSOBSERVACAO',
        label: 'Observações',
        type: 'textarea',
        size: 12,
        md: 12,
        rows: 3,
      },
    ] as Field[];
  }
  // #endregion

  // #region [ WATCHERS ]
  // #endregion

  // #region [ LIFECYCLE ]
  public created(): void {
    this.reload();
  }
  // #endregion

  // #region [ METHODS ]
  private async reload(): Promise<void> {
    this.loading.reload = true;

    try {
      this.row = await PerfilController.getDadosAdicionais();
    } finally {
      this.loading.reload = false;
    }
  }

  private cancel(): void {
    this.$confirm({
      title: 'Desfazer alterações',
      message: 'Deseja desfazer as alterações não salvas?',
      button: {
        no: 'Cancelar',
        yes: 'Sim',
      },
      callback: (confirm: boolean) => {
        if (confirm) {
          this.row = Util.deepCopy(this.backup);
        }
      },
    });
  }

  private async save(): Promise<void> {
    if (!this.validate()) {
      return;
    }

    this.loading.save = true;

    try {
      await PerfilController.salvarDadosAdicionais(this.row);
      this.$toast.success('Alterações salvas com sucesso!');
      this.reload();
    } finally {
      this.loading.save = false;
    }
  }

  private validate(): boolean {
    if (!this.validateForm()) {
      return false;
    }

    return true;
  }

  private validateForm(): boolean {
    const form = this.$refs.form;
    const isValid = (form as any).validate();

    if (!isValid) {
      this.$toast.error('Preencha os campos obrigatórios!');
    }

    return isValid;
  }
  // #endregion
}

export default DadosAdicionais;
