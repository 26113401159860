import { Vue, Component, Emit } from 'vue-property-decorator';

import Action from 'bower_components/teknisavuecomponents/assets/interfaces/Action';
import EditableTableColumn from '@/../bower_components/teknisavuecomponents/assets/interfaces/EditableTableColumn';

import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';
import PerfilController from '@/assets/ts/controller/PerfilController';

import MeuPerfilTab from '../MeuPerfilTab/MeuPerfilTab.vue';
import EditableTable from '@/../bower_components/teknisavuecomponents/components/grids/EditableTable.vue';
import NovaExperienciaPopup from '@/components/meu-perfil/NovaExperienciaPopup/NovaExperienciaPopup.vue';

@Component({
  name: 'Experiencia',
  components: {
    EditableTable,
    MeuPerfilTab,
    NovaExperienciaPopup,
  },
})
class Experiencia extends Vue {
  // #region [ PROPS ]
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('intersection')
  public emitIntersection() {}
  // #endregion

  // #region [ DATA ]
  private loading = {
    reload: false,
    delete: false,
  };

  public rows: any[] = [];

  public popupController = {
    show: false,
    edit: false,
    row: null as any,
  }

  public actions: Action[] = [
    {
      name: 'view',
      label: 'Visualizar',
      icon: 'mdi-eye',
      mode: 'view',
      onClick: this.view,
    },
    {
      name: 'edit',
      label: 'Editar',
      icon: 'mdi-pencil',
      mode: 'view',
      onClick: this.edit,
    },
    {
      name: 'delete',
      label: 'Excluir',
      icon: 'mdi-delete',
      mode: 'view',
      color: 'red',
      onClick: this.deleteBtnOnClick,
    },
  ];

  public columns: EditableTableColumn[] = [
    {
      text: 'Ocupação',
      value: 'NMOCUPACAOH',
    },
    {
      text: 'Empresa',
      value: 'DSEMPRESA',
    },
    {
      text: 'Data de Início',
      value: 'DTINICIO',
    },
    {
      text: 'Data de Fim',
      value: 'DTFIM',
    },
  ];
  // #endregion

  // #region [ COMPUTED ]
  public get isLoading(): boolean {
    return Util.anyTrue(Object.values(this.loading));
  }
  // #endregion

  // #region [ WATCHERS ]
  // #endregion

  // #region [ LIFECYCLE ]
  public created(): void {
    this.reload();
  }
  // #endregion

  // #region [ METHODS ]
  private async reload(): Promise<void> {
    this.loading.reload = true;

    try {
      this.rows = await PerfilController.getExperiencia();
    } finally {
      this.loading.reload = false;
    }
  }

  private view(row: any): void {
    this.popupController.show = true;
    this.popupController.row = row;
    this.popupController.edit = false;
  }

  private edit(row: any): void {
    this.popupController.show = true;
    this.popupController.row = row;
    this.popupController.edit = true;
  }

  public add(): void {
    this.popupController.show = true;
    this.popupController.edit = true;
    this.popupController.row = {};
  }

  private async delete(row: any): Promise<void> {
    this.loading.delete = true;
    const nrExperiencia: string = row.NREMPREGOANTERIOR;

    try {
      await PerfilController.excluirExperiencia(nrExperiencia);
      this.$toast.success('Experiência profissional excluida com sucesso!');
      this.reload();
    } finally {
      this.loading.delete = false;
    }
  }

  public onSave(): void {
    this.reload();
  }

  private deleteBtnOnClick(row: any): void {
    this.$confirm({
      title: 'Excluir experiência profissional',
      message: 'Deseja realmente EXCLUIR a experiência profissional?',
      button: {
        yes: 'Sim',
        no: 'Cancelar',
      },
      callback: (confirm) => {
        if (confirm) {
          this.delete(row);
        }
      },
    });
  }
  // #endregion
}

export default Experiencia;
