import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import * as EmailValidator from 'email-validator';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';
import CurrencyUtils from '@/../bower_components/teknisavuecomponents/assets/utils/CurrencyUtils';
import ZeedhiUtil from '@/../bower_components/teknisavuecomponents/assets/utils/ZeedhiUtil';
import VagasController from '@/assets/ts/controller/VagasController';
import VagaData from '@/assets/ts/interfaces/vagas/VagaData';
import VagaUtil from '@/assets/ts/utils/VagaUtil';
import CandidatarVagaPopup from '../CandidatarVagaPopup/CandidatarVagaPopup.vue';
import DateUtil from '@/../bower_components/teknisavuecomponents/assets/utils/DateUtil';

@Component({
  name: 'VagaCard',
  components: {
    CandidatarVagaPopup,
  },
})
class VagaCard extends Vue {
  // #region [ PROPS ]
  @Prop({ default: {} })
  private readonly value: VagaData;
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('input')
  private emitInput(): VagaData {
    return Util.deepCopy(this.internalvalue);
  }
  // #endregion

  // #region [ DATA ]
  private internalvalue: VagaData = null;

  private showDescription = false;

  private loading = {
    reload: false,
    enviar: false,
    candidatar: false,
    favoritar: false,
  };

  private clickInAction = false;

  private showCandidatarPopup = false;

  public email = '';
  public loadingIndicar = false;
  public showIndicacaoMenu = false;
  // #endregion

  // #region [ COMPUTED ]
  public get favColor(): string {
    const color = VagaUtil.vagaIsFav(this.internalvalue) ?
      'red' : 'grey';

    return color;
  }

  public get salary(): string {
    const defaultDesc = 'R$???';
    const salary = this.internalvalue.VRSALARIO;

    if (!salary) {
      return defaultDesc;
    }

    if (Number.isNaN(parseFloat(salary))) {
      return salary;
    }

    const currencySalary = CurrencyUtils.numberToCurrency(salary);

    return currencySalary;
  }

  public get isLoading(): boolean {
    return Util.anyTrue(Object.values(this.loading));
  }

  public get vagaCandidatada(): boolean {
    return VagaUtil.vagaCandidatada(this.internalvalue);
  }

  public get emailIsValid(): boolean {
    return EmailValidator.validate(this.email);
  }

  public get emailFieldsError(): string {
    if (this.emailIsValid || this.email === '') {
      return null;
    }

    return 'Insira um e-mail válido!';
  }

  private get isFav(): boolean {
    return ZeedhiUtil.snToBoolean(this.internalvalue.FAVORITA);
  }

  public get favTooltip(): string {
    const tooltip = this.isFav ?
      'Descurtir' :
      'Curtir';

    return tooltip;
  }

  public get isDisponivel(): boolean {
    const dataLimite: string = this.value.DTLIMSOLICITA;

    if (!dataLimite) {
      return true;
    }

    const disponivel = DateUtil.compareDates(dataLimite) !== -1;

    return disponivel;
  }

  public get candidatarIsVisible(): boolean {
    const isVisible =
      this.isDisponivel
      && !this.vagaCandidatada;

    return isVisible;
  }

  public get indicarIsVisible(): boolean {
    const isVisible = this.isDisponivel;
    return isVisible;
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('value', { immediate: true, deep: true })
  private valueOnChange(): void {
    if (!Util.deepCompare(this.value, this.internalvalue)) {
      this.internalvalue = Util.deepCopy(this.value);
    }
  }
  // #endregion

  // #region [ LIFECYCLE ]
  private created(): void {
    // this.confirmedFav = this.internalvalue.fav;
  }
  // #endregion

  // #region [ METHODS ]
  private async reload(): Promise<void> {
    this.loading.reload = true;

    try {
      const nrProcesso = this.internalvalue.NRPROCESSO;
      const vagasArray = await VagasController.getVagas(nrProcesso);
      this.internalvalue = vagasArray[0];

      this.emitInput();
    } finally {
      this.loading.reload = false;
    }
  }

  public onCandidatura(): void {
    this.reload();
  }

  public async toggleFav(): Promise<void> {
    // utilizando confirmedFav para o usuario favoritar
    // sem um loading (ainda sendo tolerante a erros)
    const newFav = ZeedhiUtil.toggleSN(this.internalvalue.FAVORITA);
    this.internalvalue.FAVORITA = newFav;

    this.emitInput();

    try {
      const nrOrgProcesso = this.internalvalue.NRORGPROCESSO;
      const nrProcesso = this.internalvalue.NRPROCESSO;

      const fav = await VagasController.toggleVagaFavorita(
        nrOrgProcesso, nrProcesso,
      );

      this.internalvalue.confirmedFav = ZeedhiUtil.booleanToSN(fav);
      this.internalvalue.FAVORITA = ZeedhiUtil.booleanToSN(fav);
    } catch (err) {
      this.internalvalue.FAVORITA = this.internalvalue.confirmedFav;
      this.emitInput();

      throw err;
    }
  }

  public openInfoPopup(): void {
    //
  }

  public openIndicacaoMenu(): void {
    this.showIndicacaoMenu = true;
  }

  public async sendVaga(): Promise<void> {
    if (!this.emailIsValid || this.loadingIndicar) {
      return;
    }

    this.loadingIndicar = true;

    try {
      await VagasController.enviarIndicacao(
        this.internalvalue.NRPROCESSO, this.email,
      );

      this.$toast.success('Vaga indicada com sucesso!');

      this.showIndicacaoMenu = false;
    } finally {
      this.loadingIndicar = false;
    }
  }

  public applyToVaga(): void {
    this.showCandidatarPopup = true;
  }

  public cardOnClick(): void {
    if (!this.clickInAction) {
      this.toggleShowDesc();
    }

    this.clickInAction = false;
  }

  public actionsOnClick(): void {
    this.clickInAction = true;
  }

  public toggleShowDesc(): void {
    this.showDescription = !this.showDescription;
  }
  // #endregion
}

export default VagaCard;
