import VagaData from '../interfaces/vagas/VagaData';

class VagaUtil {
  public static vagaIsFav(vaga: VagaData): boolean {
    return vaga.FAVORITA === 'S';
  }

  public static vagaCandidatada(vaga: VagaData): boolean {
    return vaga.CDSTATUS !== '0';
  }
}
export default VagaUtil;
