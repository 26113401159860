export default class RowUtils {

    public static ROW_CONTROL_PROPERTIES = [
        '__mode',
        '__is_new',
        // 'readonly',
        '__changed',
        '__backup_row',
        '__id',
    ];

    public static NEW_ROW_DEFAULT_CONTROL_PROPERTIES = {
        __id: '',
        __is_new: true,
        __mode: 'edit',
        __changed: false,
        __backup_row: {},
    }

    public static rowHasChanges(row: any) {
        // to do: readonly rows doesnt need to be compared
        // to do: store the excludedPropertites in a separated environment
    
        for (const property in row) {
          if (!this.ROW_CONTROL_PROPERTIES.includes(property)) {
            const rowHasChanges = row.__backup_row[property] !== row[property];
            if (rowHasChanges) {
              return true;
            }
          }
        }
    
        return false;
      }
}