class DocumentoUtil {
  public static validaCPF(
    cpf: string,
  ): boolean {
    if (!cpf) {
      return false;
    }

    cpf = cpf.replace(/[\D]+/g, '');

    if (cpf.length !== 11) {
      return false;
    }

    const digitoRepetido =
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999';

    if (digitoRepetido) {
      return false;
    }

    let soma = 0;

    for (let i = 0; i < 9; i++) {
      const digito = parseInt(cpf.charAt(i), 10);
      const multiplicador = 10 - i;

      soma += digito * multiplicador;
    }

    let resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.charAt(9), 10)) {
      return false;
    }

    soma = 0;

    for (let i = 0; i < 10; i++) {
      const digito = parseInt(cpf.charAt(i), 10);
      const multiplicador = 11 - i;
      soma += digito * multiplicador;
    }

    resto = (soma * 10) % 11;

    if ((resto === 10) || (resto === 11)) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.charAt(10), 10)) {
      return false;
    }

    return true;
  }

  public static validaPIS(
    pis: string,
  ): boolean {
    if (pis === '' || pis === null || pis === undefined) {
      return false;
    }

    pis = pis.replace(/[.-]/g, '');

    if (pis.length !== 11) {
      return false;
    }

    const digitoRepetido =
      pis === '00000000000' ||
      pis === '11111111111' ||
      pis === '22222222222' ||
      pis === '33333333333' ||
      pis === '44444444444' ||
      pis === '55555555555' ||
      pis === '66666666666' ||
      pis === '77777777777' ||
      pis === '88888888888' ||
      pis === '99999999999';

    if (digitoRepetido) {
      return false;
    }

    const ftap = '3298765432';
    let total = 0;
    let resto = 0;

    for (let i = 0; i < 10; i++) {
      const digito = parseInt((pis.charAt(i)), 10);
      const digitoFtap = parseInt(ftap.charAt(i), 10);
      const resultado = digito * digitoFtap;

      total += resultado;
    }

    resto = total % 11;

    if (resto !== 0) {
      resto = 11 - resto;
    }

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(pis.charAt(10), 10)) {
      return false;
    }

    return true;
  }

  public static cepIsValid(
    cep: string,
  ): boolean {
    const regEx = /^\d{5}-\d{3}$/;
    const isValid = regEx.test(cep);

    return isValid;
  }
}

export default DocumentoUtil;
