class ZeedhiUtil {
  public static snToBoolean(value: string): boolean {
    return value === 'S';
  }

  public static booleanToSN(value: boolean): string {
    return value ? 'S' : 'N';
  }

  public static toggleSN(value: string): string {
    return value === 'S' ? 'N' : 'S';
  }
}

export default ZeedhiUtil;
