import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({
  name: 'CurriculoPopup',
  components: {},
})
class CurriculoPopup extends Vue {
  // #region [ PROPS ]
  @Prop({ default: '' })
  private readonly url: string;

  @Prop({ default: false })
  private readonly value: boolean;
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('input')
  private emitInput(): boolean {
    return this.internalValue;
  }
  // #endregion

  // #region [ DATA ]
  public internalValue = false;
  // #endregion

  // #region [ COMPUTED ]
  // #endregion

  // #region [ WATCHERS ]
  @Watch('value', { immediate: true })
  private valueOnChange(): void {
    this.internalValue = this.value;
  }
  // #endregion

  // #region [ LIFECYCLE ]
  // #endregion

  // #region [ METHODS ]
  public closeDialog(): void {
    this.internalValue = false;
    this.emitInput();
  }
  // #endregion
}

export default CurriculoPopup;
