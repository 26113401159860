import Vue from 'vue';
import ExpectedError from '@/../bower_components/teknisavuecomponents/assets/utils/ExpectedError';
import HttpRequest from '@/../bower_components/teknisavuecomponents/assets/utils/HttpRequest';

class DocumentoController {
  public static async getCategorias(): Promise<any[]> {
    const route = 'getCategorias';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const categorias: any[] = response.data.dataset.data;
      return categorias;
    } catch (err: any) {
      let message = 'Não foi possível carregar as categorias!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getTipos(): Promise<any[]> {
    const route = 'getTipos';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const tipos: any[] = response.data.dataset.data;
      return tipos;
    } catch (err: any) {
      let message = 'Não foi possível carregar as tipos!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }
}

export default DocumentoController;
