var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"vaga-card",attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":_vm.cardOnClick}},[_c('div',{staticClass:"pa-3"},[_c('div',{staticClass:"vaga-card__cont-act"},[_c('div',{staticClass:"vaga-card__content"},[_c('div',{staticClass:"vaga-card__title"},[_vm._v(" "+_vm._s(_vm.internalvalue.NMPROCESSO)+" ")]),_c('div',{staticClass:"vaga-card__company vaga-card__info"},[_c('v-icon',[_vm._v("mdi-office-building")]),_vm._v(" "+_vm._s(_vm.internalvalue.NMESTRUTURAH)+" ")],1),_c('div',{staticClass:"vaga-card__location vaga-card__info"},[_c('v-icon',[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s(_vm.internalvalue.LOCALIDADE)+" ")],1),_c('div',{staticClass:"vaga-card__time vaga-card__info"},[_c('v-icon',[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(_vm.internalvalue.NMESCALATRABH)+" ")],1),_c('div',{staticClass:"vaga-card__limit vaga-card__info"},[_vm._v(" Disponível até: "+_vm._s(_vm.internalvalue.DTLIMSOLICITA)+" ")]),_c('div',{staticClass:"vaga-card__salary"},[_vm._v(" "+_vm._s(_vm.salary)+" ")])]),_c('div',{staticClass:"vaga-card__actions",on:{"click":_vm.actionsOnClick}},[_c('v-tooltip',{staticClass:"vaga-card__action",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.toggleFav}},on),[_c('v-icon',{attrs:{"color":_vm.favColor}},[_vm._v("mdi-heart")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.favTooltip)+" ")]),(_vm.indicarIsVisible)?_c('v-tooltip',{staticClass:"vaga-card__action",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-menu',_vm._g({attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.openIndicacaoMenu}},Object.assign({}, onTooltip, onMenu)),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-send")])],1)]}}],null,true),model:{value:(_vm.showIndicacaoMenu),callback:function ($$v) {_vm.showIndicacaoMenu=$$v},expression:"showIndicacaoMenu"}},onTooltip),[_c('v-card',{staticClass:"pa-2",staticStyle:{"width":"300px"}},[_c('v-text-field',{attrs:{"error-messages":_vm.emailFieldsError,"label":"E-mail"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sendVaga.apply(null, arguments)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.emailIsValid || _vm.loadingIndicar,"loading":_vm.loadingIndicar},on:{"click":_vm.sendVaga}},[_vm._v("Enviar")])],1)],1)]}}],null,false,360644742)},[_c('span',[_vm._v("Indicar")])]):_vm._e(),(_vm.candidatarIsVisible)?_c('v-tooltip',{staticClass:"vaga-card__action",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"vaga-card__action",attrs:{"color":"green"},on:{"click":_vm.applyToVaga}},[_vm._v("mdi-account-plus")])],1)]}}],null,false,807940116)},[_c('span',[_vm._v("Candidatar-se")])]):_vm._e()],1)]),_c('div',{staticClass:"vaga-card__description",class:{ 'vaga-card__description--show': _vm.showDescription }},[_vm._v(" "+_vm._s(_vm.internalvalue.DSDESCRICAO)+" ")])]),_c('CandidatarVagaPopup',{attrs:{"vaga":_vm.internalvalue},on:{"candidatura":_vm.onCandidatura},model:{value:(_vm.showCandidatarPopup),callback:function ($$v) {_vm.showCandidatarPopup=$$v},expression:"showCandidatarPopup"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }