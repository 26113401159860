import { Vue, Component, Watch } from 'vue-property-decorator';

import SalaryFilter from '@/components/vagas/SalaryFilter/SalaryFilter.vue';
import ValidDateFilter from '@/components/vagas/ValidDateFilter/ValidDateFilter.vue';
import VagaCard from '@/components/vagas/VagaCard/VagaCard.vue';

import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';
import StringUtils from '@/../bower_components/teknisavuecomponents/assets/utils/StringUtils';

import Dictionary from '@/../bower_components/teknisavuecomponents/assets/interfaces/Dictionary';
import DateUtil from '@/../bower_components/teknisavuecomponents/assets/utils/DateUtil';
import VagaData from '@/assets/ts/interfaces/vagas/VagaData';
import VagasController from '@/assets/ts/controller/VagasController';
import VagaUtil from '@/assets/ts/utils/VagaUtil';

interface SalaryFilters {
  min: number;
  max: number;
}

interface VagasFilters {
  [index: string]: {
    value: any,
    filterer: (value: any, row: VagaData) => boolean, // filtra os resultados
    fetcher?: (queryValue: string | string[]) => any, // pega o valor do filtro da query
    applier?: (value: any) => string, // retorna o valor da forma como estar na query
  };
}

@Component({
  name: 'Vagas',
  components: {
    SalaryFilter,
    ValidDateFilter,
    VagaCard,
  },
})
class Vagas extends Vue {
  // #region [ PROPS ]
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  // #endregion

  // #region [ DATA ]
  private static readonly A_COMBINAR = 'a combinar';

  public filters: VagasFilters = {
    titulo: {
      value: null as string,
      filterer: this.nomeVagaFilterer,
    },
    empresa: {
      value: null as string,
      filterer: this.empresaFilterer,
    },
    localidade: {
      value: null as string,
      filterer: this.localidadeFilterer,
    },
    'min-salario': {
      value: null as string,
      filterer: this.minSalarioFilterer,
    },
    'max-salario': {
      value: null as string,
      filterer: this.maxSalarioFilterer,
    },
    'esconder-combinar': {
      value: false,
      filterer: this.esconderACombinarFilterer,
      fetcher: this.esconderACombinarFetcher,
      applier: this.esconderACombinarApplier,
    },
    'disponivel-em': {
      value: DateUtil.getCurrentDate(),
      filterer: this.disponivelEmFilterer,
    },
    favoritas: {
      value: false,
      filterer: this.favoritasFilterer,
      fetcher: this.favoritasFetcher,
      applier: this.favoritasApplier,
    },
    status: {
      value: null as string,
      filterer: this.statusFilterer,
    },
  };

  public statusFilterItems = [
    {
      text: 'Candidato',
      value: 'candidato',
    },
    {
      text: 'Disponível',
      value: 'disponivel',
    },
  ];

  private vagas: VagaData[] = [];

  private querySetFromFilters = false;

  private loading = false;
  // #endregion

  // #region [ COMPUTED ]
  public get breakpoint(): string {
    return this.$vuetify.breakpoint.name;
  }

  public get isMobile(): boolean {
    return this.breakpoint === 'xs';
  }

  public get salaryFilterValue(): SalaryFilters {
    const filter: SalaryFilters = {
      min: this.filters['min-salario'].value,
      max: this.filters['max-salario'].value,
    };

    return filter;
  }

  public get filteredVagas(): VagaData[] {
    const filtersData = Object.values(this.filters);

    const vagas = filtersData.reduce((accumulator, filter) => {
      const value = filter.value;

      const filtered = value !== null ?
        accumulator.filter((row) => filter.filterer(value, row)) :
        accumulator;

      return filtered;
    }, this.vagas);

    return vagas;
  }

  public get statusDescription(): string {
    let description = 'Status';
    const filterValue = this.filters.status.value;

    if (filterValue) {
      const filterItem = this.statusFilterItems.find((item) => item.value === filterValue);
      description += `: ${filterItem.text}`;
    }

    return description;
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('$route.query', { immediate: true })
  private queryOnChange() {
    if (!this.querySetFromFilters) {
      this.applyFiltersFromQuery();
    } else {
      this.querySetFromFilters = false;
    }
  }
  // #endregion

  // #region [ LIFECYCLE ]
  private created(): void {
    window.vagas = this;
  }

  private mounted(): void {
    this.reload();
  }
  // #endregion

  // #region [ METHODS ]
  public salaryFiltersOnChange(filters: SalaryFilters): void {
    this.filters['min-salario'].value = filters.min;
    this.filters['max-salario'].value = filters.max;

    this.applyQueryFromFilters();
  }

  public dateFilterOnChange(): void {
    this.applyQueryFromFilters();
  }

  public async reload(): Promise<void> {
    this.loading = true;
    this.vagas = [];

    try {
      const vagas = await VagasController.getVagas();

      vagas.forEach((vaga) => {
        vaga.confirmedFav = vaga.FAVORITA;
      });

      this.vagas = vagas;
    } finally {
      this.loading = false;
    }
  }

  public aCombinarFilterOnClick(): void {
    this.filters['esconder-combinar'].value = !this.filters['esconder-combinar'].value;

    this.applyQueryFromFilters();
  }

  private applyFiltersFromQuery(): void {
    const { query } = this.$route;
    const filtersKeys = Object.keys(this.filters);

    filtersKeys.forEach((key) => {
      const queryValue = query[key] || null;
      const filter = this.filters[key];

      if (queryValue !== null) {
        filter.value = filter.fetcher ?
          filter.fetcher(queryValue) :
          queryValue;
      }
    });
  }

  private applyQueryFromFilters() {
    const filtersKeys = Object.keys(this.filters);
    const params: Dictionary = {};

    filtersKeys.forEach((key) => {
      const filter = this.filters[key];
      const value = this.filters[key].value;

      const queryValue = filter.applier ?
        filter.applier(value) :
        value;

      if (queryValue) {
        params[key] = filter.applier ?
          filter.applier(value) :
          value;
      }
    });

    const base = this.$route.path;
    const paramsString = Util.paramsToQuery(params);

    const path = base + paramsString;

    this.$router.push(path);
  }

  public searchBtnOnClick(): void {
    this.applyQueryFromFilters();
  }

  private vagaIsFav(row: any): boolean {
    return row.NRPROCFAVORITOS !== '-1';
  }

  private getFavColor(row: any): string {
    const isFav = this.vagaIsFav(row);
    const color = isFav ? 'red' : 'grey';

    return color;
  }

  private getFavDesc(row: any): string {
    const isFav = this.vagaIsFav(row);
    const desc = isFav ? 'Desmarcar como favorito' : 'Marcar como favorito';

    return desc;
  }

  public favFilterOnClick(): void {
    this.filters.favoritas.value = !this.filters.favoritas.value;

    this.applyQueryFromFilters();
  }

  private salarioIsACombinar(salario: string): boolean {
    return salario === Vagas.A_COMBINAR;
  }

  public vagaCardOnInput(newValue: VagaData): void {
    Util.replaceItem(newValue, this.vagas, (vaga) => vaga.NRPROCESSO === newValue.NRPROCESSO);
  }

  public statusFilterOnSelection(value: string): void {
    this.filters.status.value = value;

    this.applyQueryFromFilters();
  }

  // FAVORITAS
  private favoritasFilterer(value: boolean, row: VagaData): boolean {
    return value ? VagaUtil.vagaIsFav(row) : true;
  }

  private favoritasFetcher(queryValue: string | string[]): boolean {
    return queryValue === 'true';
  }

  private favoritasApplier(value: boolean): string {
    const queryValue = value === true ? 'true' : null;
    return queryValue;
  }

  // NOME VAGA
  private nomeVagaFilterer(value: string, row: VagaData): boolean {
    return StringUtils.includesIgnoreSpecial(row.NMPROCESSO, value);
  }

  // EMPRESA
  private empresaFilterer(value: string, row: VagaData): boolean {
    return StringUtils.includesIgnoreSpecial(row.NMESTRUTURAH, value);
  }

  // LOCALIDADE
  private localidadeFilterer(value: string, row: VagaData): boolean {
    return StringUtils.includesIgnoreSpecial(row.LOCALIDADE, value);
  }

  // SALÁRIO
  private minSalarioFilterer(value: string, row: VagaData): boolean {
    if (this.salarioIsACombinar(row.VRSALARIO)) {
      return true;
    }

    const vagaSalario = parseFloat(row.VRSALARIO);
    const filtroSalario = parseFloat(value);

    return vagaSalario >= filtroSalario;
  }

  private maxSalarioFilterer(value: string, row: VagaData): boolean {
    const filtroSalario = parseFloat(value);

    if (!filtroSalario || this.salarioIsACombinar(row.VRSALARIO)) {
      return true;
    }

    const vagaSalario = parseFloat(row.VRSALARIO);

    return vagaSalario <= filtroSalario;
  }

  // ESCONDER A COMBINAR
  private esconderACombinarFilterer(value: boolean, row: VagaData): boolean {
    return !value || !this.salarioIsACombinar(row.VRSALARIO);
  }

  private esconderACombinarFetcher(queryValue: string | string[]): boolean {
    return queryValue === 'true';
  }

  private esconderACombinarApplier(value: boolean): string {
    return value === true ? 'true' : null;
  }

  // DISPONÍVEL EM
  private disponivelEmFilterer(value: string, row: VagaData): boolean {
    // a data limite é após a data do filtro
    return DateUtil.compareDates(row.DTLIMSOLICITA, value) >= 0;
  }

  // STATUS
  private statusFilterer(value: string, row: VagaData): boolean {
    const disponivel = value === 'disponivel';

    if (disponivel) {
      return row.CDSTATUS === '0';
    }

    return row.CDSTATUS !== '0';
  }
  // #endregion
}

export default Vagas;
