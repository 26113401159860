import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';
import Field from '@/../bower_components/teknisavuecomponents/assets/interfaces/Field';
import TkForm from '@/../bower_components/teknisavuecomponents/components/TkForm.vue';
import DateUtil from '@/../bower_components/teknisavuecomponents/assets/utils/DateUtil';
import PerfilController from '@/assets/ts/controller/PerfilController';
import GeralController from '@/assets/ts/controller/GeralController';

@Component({
  name: 'NovaExperienciaPopup',
  components: {
    TkForm,
  },
})
class NovaExperienciaPopup extends Vue {
  // #region [ PROPS ]
  @Prop({ default: false })
  private readonly edit: boolean;

  @Prop({ default: null })
  private readonly row: any;

  @Prop({ default: false })
  private readonly value: boolean;
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('input')
  private emitInput() {
    return this.internalValue;
  }

  @Emit('save')
  private emitSave() {}
  // #endregion

  // #region [ DATA ]
  private readonly defaultRow: any = {
    NRTIPOFORMACAO: null,
    NRAREAFORMACAO: null,
    CDPAIS: null,
    SGESTADO: null,
    CDMUNICIPIO: null,
    NMOCUPACAOH: null,
  };

  private loadedData = false;

  public loading = false;

  private readonly defaultErrorMessage = 'Não foi possível carregar!';

  private internalValue = false;
  private internalRow: any = {};
  private internalEdit = false;

  private fieldsData = {
    ocupacoes: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    tiposServico: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    simNao: {
      items: [
        {
          value: 'S',
          description: 'Sim',
        },
        {
          value: 'N',
          description: 'Não',
        },
      ],
    },
    paises: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    estados: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    cidades: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    motivosResc: {
      loading: false,
      error: false,
      items: [] as any[],
    },
  };
  // #endregion

  // #region [ COMPUTED ]
  public get mode(): string {
    return this.internalEdit ? 'edit' : 'view';
  }

  public get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs;
  }

  public get fields(): Field[] {
    return [
      {
        name: 'DSEMPRESA',
        label: 'Empresa',
        type: 'text',
        size: 12,
        required: true,
      },
      {
        name: 'NROCUPACAOM',
        label: 'Ocupação',
        type: 'autocomplete',
        size: 6,
        valueField: 'NROCUPACAOM',
        descriptionField: 'NMOCUPACAOH',
        items: this.fieldsData.ocupacoes.items,
        loading: this.fieldsData.ocupacoes.loading,
        readonly: this.fieldsData.ocupacoes.loading,
        errorMessages: this.fieldsData.ocupacoes.error ? this.defaultErrorMessage : '',
        required: true,
        outData: {
          NROCUPACAOM: 'NROCUPACAOM',
          NMOCUPACAOH: 'NMOCUPACAOH',
        },
      },
      {
        name: 'DSOCUPACAO',
        label: 'Outra Ocupação',
        type: 'text',
        size: 6,
        required: this.internalRow.NROCUPACAOM === '1',
        readonly: this.internalRow.NROCUPACAOM !== '1',
      },
      {
        name: 'DSREFERENCIA',
        label: 'Pessoa de Referência',
        type: 'text',
        size: 6,
      },
      {
        name: 'DSTELREFERENCIA',
        label: 'Telefone Referência',
        type: 'text',
        size: 6,
      },
      {
        name: 'IDTIPOSERVICO',
        label: 'Tipo de Serviço',
        type: 'autocomplete',
        size: 6,
        valueField: 'NRTIPOVINCULOM',
        descriptionField: 'NMTIPOVINCULOH',
        items: this.fieldsData.tiposServico.items,
        loading: this.fieldsData.tiposServico.loading,
        errorMessages: this.fieldsData.tiposServico.error ? this.defaultErrorMessage : '',
        required: true,
      },
      {
        name: 'DTINICIO',
        label: 'Início',
        type: 'date',
        size: 3,
        hideDatePicker: true,
        required: true,
      },
      {
        name: 'DTFIM',
        label: 'Fim',
        type: 'date',
        size: 3,
        hideDatePicker: true,
      },
      {
        name: 'NRREMUNERACAO',
        label: 'Remuneração',
        type: 'number',
        size: 6,
      },
      {
        name: 'IDCOMISSAO',
        label: 'Comissão',
        type: 'select',
        size: 6,
        valueField: 'value',
        descriptionField: 'description',
        items: this.fieldsData.simNao.items,
      },
      {
        name: 'CDPAIS',
        label: 'País',
        type: 'autocomplete',
        size: 6,
        valueField: 'CDPAIS',
        descriptionField: 'NMPAIS',
        items: this.fieldsData.paises.items,
        loading: this.fieldsData.paises.loading,
        errorMessages: this.fieldsData.paises.error ? this.defaultErrorMessage : '',
        onInput: this.paisOnChange,
      },
      {
        name: 'SGESTADO',
        label: 'Estado',
        type: 'autocomplete',
        size: 6,
        valueField: 'SGESTADO',
        descriptionField: 'NMESTADO',
        items: this.fieldsData.estados.items,
        loading: this.fieldsData.estados.loading,
        errorMessages: this.fieldsData.estados.error ? this.defaultErrorMessage : '',
        readonly: !this.paisIsSet,
        onInput: this.estadoOnChange,
      },
      {
        name: 'CDMUNICIPIO',
        label: 'Cidade',
        type: 'autocomplete',
        size: 6,
        valueField: 'CDMUNICIPIO',
        descriptionField: 'NMMUNICIPIO',
        items: this.fieldsData.cidades.items,
        loading: this.fieldsData.cidades.loading,
        errorMessages: this.fieldsData.cidades.error ? this.defaultErrorMessage : '',
        readonly: !this.paisIsSet || !this.estadoIsSet,
      },
      {
        name: 'NRMOTIVORESC',
        label: 'Motivo da Rescisão',
        type: 'autocomplete',
        size: 6,
        valueField: 'NRMOTIVORESC',
        descriptionField: 'NMMOTIVORESC',
        items: this.fieldsData.motivosResc.items,
        loading: this.fieldsData.motivosResc.loading,
        errorMessages: this.fieldsData.motivosResc.error ? this.defaultErrorMessage : '',
      },
    ];
  }

  private get paisIsSet(): boolean {
    return !Util.isUndefinedOrNull(this?.internalRow?.CDPAIS);
  }

  private get estadoIsSet(): boolean {
    return !Util.isUndefinedOrNull(this?.internalRow?.SGESTADO);
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('value', { immediate: true })
  private valueOnChange(): void {
    this.internalValue = this.value;

    if (this.internalValue) {
      if (this.row === null) {
        this.internalRow = {};
      } else {
        this.internalRow = Util.deepCopy(this.row);
      }

      this.setDefaultRow();

      if (!this.loadedData) {
        this.loadedData = true;
        this.loadFields();
      }

      this.loadEstados();
      this.loadCidades();

      this.scrollToTop();
    }
  }

  @Watch('edit', { immediate: true })
  private editeOnChange(): void {
    this.internalEdit = this.edit;
  }
  // #endregion

  // #region [ LIFECYCLE ]
  // #endregion

  // #region [ METHODS ]
  public closeDialog(): void {
    this.internalValue = false;
    this.emitInput();
  }

  private setDefaultRow(): void {
    this.internalRow = {
      ...this.defaultRow,
      ...this.internalRow,
    };
  }

  public async save(): Promise<void> {
    if (!this.validate()) {
      return;
    }

    this.loading = true;

    try {
      await PerfilController.salvarExperiencia(this.internalRow);
      this.emitSave();
      this.closeDialog();
    } finally {
      this.loading = false;
    }
  }

  private loadFields(): void {
    this.loadOcupacoes();
    this.loadPaises();
    this.loadPaises();
    this.loadTiposServico();
    this.loadMotivosResc();
  }

  private async loadOcupacoes(): Promise<void> {
    const fieldData = this.fieldsData.ocupacoes;

    fieldData.loading = true;
    fieldData.error = false;

    try {
      this.fieldsData.ocupacoes.items = await PerfilController.getOcupacoes();
    } catch (err) {
      fieldData.error = true;
      throw err;
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadPaises(): Promise<void> {
    const fieldData = this.fieldsData.paises;

    fieldData.loading = true;
    fieldData.error = false;

    try {
      this.fieldsData.paises.items = await GeralController.getPais();
    } catch (err) {
      fieldData.error = true;
      throw err;
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadEstados(): Promise<void> {
    const fieldData = this.fieldsData.estados;
    const cdPais: string = this.internalRow.CDPAIS;

    if (Util.isUndefinedOrNull(cdPais)) {
      return;
    }

    fieldData.loading = true;
    fieldData.error = false;

    try {
      this.fieldsData.estados.items = await GeralController.getEstado(cdPais);
    } catch (err) {
      fieldData.error = true;
      throw err;
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadCidades(): Promise<void> {
    const fieldData = this.fieldsData.cidades;
    const cdPais: string = this.internalRow.CDPAIS;
    const sgEstado: string = this.internalRow.SGESTADO;

    if (Util.isUndefinedOrNull(cdPais) || Util.isUndefinedOrNull(sgEstado)) {
      return;
    }

    fieldData.loading = true;
    fieldData.error = false;

    try {
      this.fieldsData.cidades.items = await GeralController.getMunicipio(cdPais, sgEstado);
    } catch (err) {
      fieldData.error = true;
      throw err;
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadTiposServico(): Promise<void> {
    const fieldData = this.fieldsData.tiposServico;

    fieldData.loading = true;
    fieldData.error = false;

    try {
      this.fieldsData.tiposServico.items = await PerfilController.getTiposServico();
    } catch (err) {
      fieldData.error = true;
      throw err;
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadMotivosResc(): Promise<void> {
    const fieldData = this.fieldsData.motivosResc;

    fieldData.loading = true;
    fieldData.error = false;

    try {
      this.fieldsData.motivosResc.items = await PerfilController.getMotivosRescisao();
    } catch (err) {
      fieldData.error = true;
      throw err;
    } finally {
      fieldData.loading = false;
    }
  }

  private paisOnChange(): void {
    this.internalRow.SGESTADO = null;
    this.internalRow.CDMUNICIPIO = null;

    if (this.paisIsSet) {
      this.loadEstados();
    }
  }

  private estadoOnChange(): void {
    this.internalRow.CDMUNICIPIO = null;

    if (this.estadoIsSet) {
      this.loadCidades();
    }
  }

  private validate(): boolean {
    if (!this.validateForm()) {
      return false;
    }

    if (!this.validateDates()) {
      return false;
    }

    return true;
  }

  private validateForm(): boolean {
    const form: any = this.$refs.form;
    const isValid: boolean = form.validate();

    if (!isValid) {
      this.$toast.error('Preencha todos os campos obrigatórios!');
    }

    return isValid;
  }

  private validateDates(): boolean {
    const dtInicio: string = this.internalRow.DTINICIO;
    const dtFim: string = this.internalRow.DTFIM;

    if (!dtInicio || !dtFim) {
      return true;
    }

    const comparison = DateUtil.compareDates(dtInicio, dtFim);

    // se for 1, a data de inicio é maior que a de fim (inválida)
    const isValid = comparison !== 1;

    if (!isValid) {
      this.$toast.error('A data de início deve ser anterior à de fim!');
    }

    return isValid;
  }

  private async scrollToTop(): Promise<void> {
    await Util.setTimeout(50);
    const formWrap: any = this.$refs['form-wrap'];

    if (formWrap) {
      formWrap.scrollTop = 0;
    }
  }
  // #endregion
}

export default NovaExperienciaPopup;
