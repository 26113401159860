import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';

import Field from '@/../bower_components/teknisavuecomponents/assets/interfaces/Field';
import TkForm from '@/../bower_components/teknisavuecomponents/components/TkForm.vue';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';

import DateUtil from '@/../bower_components/teknisavuecomponents/assets/utils/DateUtil';
import PerfilController from '@/assets/ts/controller/PerfilController';



@Component({
  name: 'NovaFormacaoPopup',
  components: {
    TkForm,
  },
})
class NovaFormacaoPopup extends Vue {
  // #region [ PROPS ]
  @Prop({ default: false })
  private readonly edit: boolean;

  @Prop({ default: null })
  private readonly row: any;

  @Prop({ default: false })
  private readonly value: boolean;
  // #endregion

  // #region [ EVENTS ]
  @Emit('input')
  private emitInput() {
    return this.internalValue;
  }

  @Emit('save')
  private emitSave() {}
  // #endregion

  // #region [ DATA ]
  private readonly defaultRow: any = {
    NRTIPOFORMACAO: null,
    NRAREAFORMACAO: null,
    __is_new: true,
  };

  private loadedData = false;

  private readonly defaultErrorMessage = 'Não foi possível carregar!';

  private internalValue = false;

  private internalRow: any = {};

  private loading = false;

  private fieldsData = {
    tiposFormacao: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    cursos: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    instituicoes: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    areasFormacao: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    idiomas: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    niveisIdioma: {
      items: [
        {
          value: 'BASICO',
          description: 'Básico',
        },
        {
          value: 'INTERMED',
          description: 'Intermediário',
        },
        {
          value: 'AVANCADO',
          description: 'Avançado',
        },
      ],
    },
  };
  // #endregion

  // #region [ COMPUTED ]
  private get fields(): Field[] {
    const fields: Field[] = [
      {
        name: 'NRTIPOFORMACAO',
        label: 'Tipo de Formação',
        type: 'autocomplete',
        size: 12,
        required: true,
        descriptionField: 'DSTIPOFORMACAO',
        valueField: 'NRTIPOFORMACAO',
        items: this.fieldsData.tiposFormacao.items,
        loading: this.fieldsData.tiposFormacao.loading,
        readonly: this.fieldsData.tiposFormacao.loading,
        errorMessages: this.fieldsData.tiposFormacao.error ? this.defaultErrorMessage : null,
        outData: {
          NRTIPOFORMACAO: 'NRTIPOFORMACAO',
          DSTIPOFORMACAO: 'DSTIPOFORMACAO',
        },
        onInput: this.tipoFormacaoOnInput,
      },
      {
        name: 'NRCURSO',
        label: 'Curso',
        type: 'autocomplete',
        size: 6,
        required: true,
        descriptionField: 'NMCURSO',
        valueField: 'NRCURSO',
        items: this.fieldsData.cursos.items,
        loading: this.fieldsData.cursos.loading,
        readonly: this.fieldsData.cursos.loading || this.tipoFormacaoIsIdioma,
        errorMessages: this.fieldsData.cursos.error ? this.defaultErrorMessage : null,
        outData: {
          NRCURSO: 'NRCURSO',
          NMCURSO: 'NMCURSO',
        },
      },
      {
        name: 'DSCURSO',
        label: 'Outro Curso',
        type: 'text',
        size: 6,
        required: this.outroCurso,
        readonly: !this.outroCurso,
      },
      {
        name: 'CDIDIOM',
        label: 'Idioma',
        type: 'autocomplete',
        size: 6,
        required: true,
        valueField: 'CDIDIOM',
        descriptionField: 'NMIDIOMA',
        items: this.fieldsData.idiomas.items,
        loading: this.fieldsData.idiomas.loading,
        readonly: this.fieldsData.idiomas.loading,
        isVisible: this.tipoFormacaoIsIdioma,
      },
      {
        name: 'IDNVIDIOMA',
        label: 'Nível',
        type: 'select',
        size: 6,
        required: true,
        valueField: 'value',
        descriptionField: 'description',
        items: this.fieldsData.niveisIdioma.items,
        isVisible: this.tipoFormacaoIsIdioma,
      },
      {
        name: 'DSINSTITUICAO',
        label: 'Instituição',
        type: 'text',
        size: 8,
        required: true,
      },
      {
        name: 'NRPERIODO',
        label: 'Período',
        type: 'number',
        size: 4,
        required: true,
        min: 1,
        max: 99,
      },
      {
        name: 'DTINICIO',
        label: 'Início',
        type: 'date',
        size: 3,
        required: true,
        hideDatePicker: true,
        rules: [
          // this.getDateErrorMessages,
        ],
        validateOnBlur: true,
      },
      {
        name: 'DTFIM',
        label: 'Fim',
        type: 'date',
        size: 3,
        hideDatePicker: true,
        rules: [
          // this.getDateErrorMessages,
        ],
        validateOnBlur: true,
      },
      {
        name: 'NRCARGAHORARIA',
        label: 'Carga Horário (Horas)',
        type: 'number',
        size: 6,
      },
      {
        name: 'NRAREAFORMACAO',
        label: 'Área de Formação',
        type: 'autocomplete',
        size: 6,
        descriptionField: 'NMAREAFORMACAO',
        valueField: 'NRAREAFORMACAO',
        items: this.fieldsData.areasFormacao.items,
        loading: this.fieldsData.areasFormacao.loading,
        readonly: this.fieldsData.areasFormacao.loading || this.tipoFormacaoIsIdioma,
        errorMessages: this.fieldsData.areasFormacao.error ? this.defaultErrorMessage : null,
      },
      {
        name: 'DES_AREAFORMACAO_NRORG',
        label: 'Outra Área de Formação',
        type: 'text',
        size: 6,
        required: this.outraArea,
        readonly: !this.outraArea,
      },
      {
        name: 'DSOBSERVACAO',
        label: 'Observação',
        type: 'textarea',
        size: 12,
        rows: 2,
      },
    ];

    return fields;
  }

  private get tipoFormacaoIsIdioma(): boolean {
    return this.internalRow.NRTIPOFORMACAO === '2';
  }

  private get outroCurso(): boolean {
    const curso = this.internalRow.NRCURSO;
    const isOutro = curso === '2';

    return isOutro;
  }

  private get outraArea(): boolean {
    const area = this.internalRow.NRAREAFORMACAO;
    const isOutra = area === '2';

    return isOutra;
  }

  public get mode(): string {
    return this.edit ? 'edit' : 'view';
  }
  // #endregion

  // #region [ LIFECYCLE ]
  private created(): void {
    //
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('value', { immediate: true })
  private valueOnChange(): void {
    this.internalValue = this.value;

    if (this.internalValue) {
      if (this.row === null) {
        this.internalRow = {};
      } else {
        this.internalRow = Util.deepCopy(this.row);
      }

      this.setDefaultRow();

      if (!this.loadedData) {
        this.loadedData = true;
        this.loadFields();
      }

      this.scrollToTop();
    }
  }
  // #endregion

  // #region [ METHODS ]
  public closeDialog(): void {
    this.internalValue = false;
    this.emitInput();
  }

  public async save(): Promise<void> {
    if (!this.validate()) {
      return;
    }

    this.loading = true;

    try {
      await PerfilController.salvarFormacao(this.internalRow);
      this.emitSave();
      this.closeDialog();
    } finally {
      this.loading = false;
    }
  }

  private loadFields(): void {
    this.loadTiposFormacao();
    this.loadCursos();
    // this.loadInstituicoes();
    this.loadAreasFormacao();
    this.loadIdiomas();
  }

  private async loadTiposFormacao(): Promise<void> {
    this.fieldsData.tiposFormacao.loading = true;
    this.fieldsData.tiposFormacao.error = false;

    try {
      this.fieldsData.tiposFormacao.items = await PerfilController.getTipoFormacaoParc();
    } catch {
      this.fieldsData.tiposFormacao.error = true;
    } finally {
      this.fieldsData.tiposFormacao.loading = false;
    }
  }

  private async loadCursos(): Promise<void> {
    this.fieldsData.cursos.loading = true;
    this.fieldsData.cursos.error = false;

    try {
      this.fieldsData.cursos.items = await PerfilController.getCursoAcademico();
    } catch (err) {
      this.fieldsData.cursos.error = true;
      throw err;
    } finally {
      this.fieldsData.cursos.loading = false;
    }
  }

  private async loadInstituicoes(): Promise<void> {
    this.fieldsData.instituicoes.loading = true;
    this.fieldsData.instituicoes.error = false;

    try {
      this.fieldsData.instituicoes.items = await PerfilController.getInstituicaoEstrutura();
    } catch (err) {
      this.fieldsData.instituicoes.error = true;
      throw err;
    } finally {
      this.fieldsData.instituicoes.loading = false;
    }
  }

  private async loadAreasFormacao(): Promise<void> {
    this.fieldsData.areasFormacao.loading = true;
    this.fieldsData.areasFormacao.error = false;

    try {
      this.fieldsData.areasFormacao.items = await PerfilController.getAreaFormacao();
    } catch (err) {
      this.fieldsData.areasFormacao.error = true;
      throw err;
    } finally {
      this.fieldsData.areasFormacao.loading = false;
    }
  }

  private async loadIdiomas(): Promise<void> {
    this.fieldsData.idiomas.loading = true;
    this.fieldsData.idiomas.error = false;

    try {
      this.fieldsData.idiomas.items = await PerfilController.getIdiomas();
    } catch (err) {
      this.fieldsData.idiomas.error = true;
      throw err;
    } finally {
      this.fieldsData.idiomas.loading = false;
    }
  }

  private setDefaultRow(): void {
    this.internalRow = {
      ...this.defaultRow,
      ...this.internalRow,
    };
  }

  private validate(): boolean {
    if (!this.validateFields()) {
      return false;
    }

    if (!this.validateDates()) {
      return false;
    }

    if (!this.validateHoras()) {
      return false;
    }

    return true;
  }

  private validateDates(): boolean {
    const dtInicio: string = this.internalRow.DTINICIO;
    const dtFim: string = this.internalRow.DTFIM;

    if (!dtInicio || !dtFim) {
      return true;
    }

    const comparison = DateUtil.compareDates(dtInicio, dtFim);

    // se for 1, a data de inicio é maior que a de fim (inválida)
    const isValid = comparison !== 1;

    if (!isValid) {
      this.$toast.error('A data de início deve ser anterior à de fim!');
    }

    return isValid;
  }

  private validateHoras(): boolean {
    const cargaHoraria: number = this.internalRow.NRCARGAHORARIA;

    if (cargaHoraria <= 0) {
      const message = 'A carga horário deve ser maior que 0 horas!';
      this.$toast.error(message);

      return false;
    }

    return true;
  }

  private validateFields(): boolean {
    const isValid: boolean = (this.$refs.form as any).validate(true);

    if (!isValid) {
      const message = 'Preencha todos os campos obrigatórios!';
      this.$toast.error(message);
    }

    return isValid;
  }

  private getDateErrorMessages(value: string): string | boolean {
    if (!value || !DateUtil.isValid(value)) {
      return 'Insira um data válida!';
    }

    return true;
  }

  private tipoFormacaoOnInput(): void {
    if (!this.tipoFormacaoIsIdioma) {
      this.internalRow.CDIDIOM = null;
      this.internalRow.IDNVIDIOMA = null;
    } else {
      this.internalRow.NRCURSO = '3';
      this.internalRow.NRAREAFORMACAO = '3';

      const cursoLinguagens =
        this.fieldsData.cursos.items.find((curso) => curso.NRCURSO === '3');

      const areaIdiomas =
        this.fieldsData.areasFormacao.items.find((area) => area.NRAREAFORMACAO === '3');

      this.internalRow.NMCURSO = cursoLinguagens?.NMCURSO || 'Linguagens';
      this.internalRow.NMAREAFORMACAO = areaIdiomas?.NMAREAFORMACAO || 'Idiomas';
    }
  }

  private async scrollToTop(): Promise<void> {
    await Util.setTimeout(50);
    const formWrap: any = this.$refs['form-wrap'];

    if (formWrap) {
      formWrap.scrollTop = 0;
    }
  }
  // #endregion
}

export default NovaFormacaoPopup;
