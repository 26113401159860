import { Vue, Component, Emit } from 'vue-property-decorator';
import Field from '@/../bower_components/teknisavuecomponents/assets/interfaces/Field';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';

import TkForm from '@/../bower_components/teknisavuecomponents/components/TkForm.vue';
import PerfilController from '@/assets/ts/controller/PerfilController';
import ToolbarAction from '@/../bower_components/teknisavuecomponents/assets/interfaces/ToolbarAction';
import MeuPerfilTab from '@/components/meu-perfil/MeuPerfilTab/MeuPerfilTab.vue';
import StringUtils from '@/../bower_components/teknisavuecomponents/assets/utils/StringUtils';


@Component({
  name: 'Contato',
  components: {
    TkForm,
    MeuPerfilTab,
  },
})
class Contato extends Vue {
  // #region [ PROPS ]
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('intersection')
  public emitIntersection() {}
  // #endregion

  // #region [ DATA ]
  public row: any = {};
  public backup: any = {};

  private loading = {
    reload: false,
    save: false,
  };

  public actions: ToolbarAction[] = [
    {
      name: 'save',
      label: 'Salvar',
      mode: 'edit',
      color: 'green',
      icon: 'mdi-content-save',
      onClick: this.save,
    },
    {
      name: 'cancel',
      label: 'Desfazer alterações',
      mode: 'edit',
      color: 'red',
      icon: 'mdi-close-circle',
      onClick: this.cancel,
    },
  ];
  // #endregion

  // #region [ COMPUTED ]
  public get isLoading(): boolean {
    return Util.anyTrue(Object.values(this.loading));
  }

  public get fields(): Field[] {
    return [
      {
        name: 'REDESOCIAL',
        label: 'LinkedIn',
        labelIcon: 'mdi-linkedin',
        type: 'text',
        size: 12,
        md: 6,
      },
      {
        name: 'HOMEPAGE',
        label: 'Website',
        type: 'text',
        size: 12,
        md: 6,
      },
      {
        name: 'TELPRINCIPAL',
        label: 'Telefone',
        type: 'text',
        size: 12,
        md: 3,
        mask: '(##)####-####',
        rules: [
          this.validateTelefone,
        ],
        validateOnBlur: true,
      },
      {
        name: 'CELPARTIC',
        label: 'Celular',
        type: 'text',
        size: 12,
        md: 3,
        mask: '(##)#####-####',
        rules: [
          this.validateCelular,
        ],
        validateOnBlur: true,
      },
    ] as Field[];
  }
  // #endregion

  // #region [ WATCHERS ]
  // #endregion

  // #region [ LIFECYCLE ]
  public created(): void {
    this.reload();
  }
  // #endregion

  // #region [ METHODS ]
  private async reload(): Promise<void> {
    this.loading.reload = true;

    try {
      this.row = await PerfilController.getContato();
      this.formatRow();
    } finally {
      this.loading.reload = false;
    }
  }

  private formatRow(): void {
    const celular: string = this.row.CELPARTIC;
    const telefone: string = this.row.TELPRINCIPAL;

    if (celular) {
      this.row.CELPARTIC = StringUtils.insertDashInPhone(celular);
    }

    if (telefone) {
      this.row.TELPRINCIPAL = StringUtils.insertDashInPhone(telefone);
    }
  }

  private cancel(): void {
    this.$confirm({
      title: 'Desfazer alterações',
      message: 'Deseja desfazer as alterações não salvas?',
      button: {
        no: 'Cancelar',
        yes: 'Sim',
      },
      callback: (confirm: boolean) => {
        if (confirm) {
          this.row = Util.deepCopy(this.backup);
        }
      },
    });
  }

  private async save(): Promise<void> {
    if (!this.validate()) {
      return;
    }

    this.loading.save = true;

    try {
      await PerfilController.salvarContato(this.row);
      this.$toast.success('Alterações salvas com sucesso!');
      this.reload();
    } finally {
      this.loading.save = false;
    }
  }

  private validate(): boolean {
    if (!this.validateForm()) {
      return false;
    }

    return true;
  }

  private validateForm(): boolean {
    const form = this.$refs.form;
    const isValid = (form as any).validate();

    if (!isValid) {
      this.$toast.error('Preencha os campos obrigatórios!');
    }

    return isValid;
  }

  private getPhoneLength(value: string): number {
    if (!value) {
      return 0;
    }

    const charsToIgnore = [
      '+',
      '(',
      ')',
      '-',
      ' ',
    ];

    const reducedPhone = charsToIgnore.reduce(
      (accumulator, char) => accumulator.replace(char, ''),
      value,
    );

    const length = reducedPhone.length;
    return length;
  }

  private validateCelular(value: string): boolean | string {
    const celularLength = this.getPhoneLength(value);

    if (celularLength < 11 && celularLength !== 0) {
      return 'Número de celular inválido!';
    }

    return true;
  }

  private validateTelefone(value: string): boolean | string {
    const celularLength = this.getPhoneLength(value);

    if (celularLength < 8 && celularLength !== 0) {
      return 'Número de telefone inválido!';
    }

    return true;
  }
  // #endregion
}

export default Contato;
