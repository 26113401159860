import Vue from 'vue';
import HttpRequest from '@/../bower_components/teknisavuecomponents/assets/utils/HttpRequest';
import ExpectedError from '@/../bower_components/teknisavuecomponents/assets/utils/ExpectedError';

class PerfilController {
  public static async generateResume(
    showLoading = true,
  ): Promise<string> {
    const route = 'generateResume';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, showLoading);
      ExpectedError.throwResponseError(response);

      const link: string = response.data.dataset.data.link;
      return link;
    } catch (err) {
      let message = 'Não foi possível gerar o currículo!';

      if (err instanceof Error) {
        message = ExpectedError.getResponseErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getTipoFormacaoParc(): Promise<any[]> {
    const route = 'getTipoFormacaoParc';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os tipos de formação!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getCursoAcademico(): Promise<any[]> {
    const route = 'getCursoAcademico';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os cursos!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getInstituicaoEstrutura(): Promise<any[]> {
    const route = 'getInstituicaoEstrutura';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar as instituições!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getAreaFormacao(): Promise<any[]> {
    const route = 'getAreaFormacao';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os cursos!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getIdiomas(): Promise<any[]> {
    const route = 'getIdiomas';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os idiomas!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getOcupacoes(): Promise<any[]> {
    const route = 'getOcupacoes';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar as ocupações!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getTiposServico(): Promise<any[]> {
    const route = 'getTiposServico';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os tipos de serviço!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getMotivosRescisao(): Promise<any[]> {
    const route = 'getMotivosRescisao';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os motivos de!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getDadosPessoais(): Promise<any> {
    const route = 'getDadosPessoais';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      let result: any = response.data.dataset.data;

      if (Array.isArray(result)) {
        result = {};
      }

      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os dados pessoais!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getDadosAdicionais(): Promise<any> {
    const route = 'getDadosAdicionais';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      let result: any = response.data.dataset.data;

      if (Array.isArray(result)) {
        result = {};
      }

      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os dados adicionais!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getEndereco(): Promise<any> {
    const route = 'getEndereco';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      let result: any = response.data.dataset.data;

      if (Array.isArray(result)) {
        result = {};
      }

      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os endereço!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getContato(): Promise<any> {
    const route = 'getContato';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      let result: any = response.data.dataset.data;

      if (Array.isArray(result)) {
        result = {};
      }

      return result;
    } catch (err) {
      let message = 'Não foi possível carregar as formas de contato!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getFormacao(): Promise<any[]> {
    const route = 'getFormacao';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar as formações!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getExperiencia(): Promise<any[]> {
    const route = 'getExperiencia';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar as experiências profissionais!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getDocumentos(): Promise<any[]> {
    const route = 'getDocumentos';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os documentos!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async salvarDadosPessoais(
    row: any,
  ): Promise<void> {
    const route = 'salvarDadosPessoais';

    const params = {
      requestType: 'Row',
      row,
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err) {
      let message = 'Não foi possível salvar os dados pessoais!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getEstadoCivil(): Promise<any[]> {
    const route = 'getEstadocivil';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os estados civis!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getNacionalidade(): Promise<any[]> {
    const route = 'getNacionalidade';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar as nacionalidades!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getGrauInstrucao(): Promise<any[]> {
    const route = 'getGrauinstrucao';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os graus de instrução!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getLogradouro(): Promise<any[]> {
    const route = 'getLogradouro';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os logradouros!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async salvarDadosAdicionais(
    row: any,
  ): Promise<void> {
    const route = 'salvarDadosAdicionais';

    const params = {
      requestType: 'Row',
      row,
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err) {
      let message = 'Não foi possível salvar os dados adicionais!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async salvarEndereco(
    row: any,
  ): Promise<void> {
    const route = 'salvarEndereco';

    const params = {
      requestType: 'Row',
      row,
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err) {
      let message = 'Não foi possível salvar o endereço!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async salvarContato(
    row: any,
  ): Promise<void> {
    const route = 'salvarContato';

    const params = {
      requestType: 'Row',
      row,
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err) {
      let message = 'Não foi possível salvar o contato!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async salvarFormacao(
    row: any,
  ): Promise<void> {
    const route = 'salvarFormacao';

    const params = {
      requestType: 'Row',
      row,
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err) {
      let message = 'Não foi possível salvar a formação!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async excluirFormacao(
    nrFormacao: string,
  ): Promise<void> {
    const route = 'excluirFormacao';

    const params = {
      requestType: 'Row',
      row: {
        NRFORMACAO: nrFormacao,
      },
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err) {
      let message = 'Não foi possível excluir a formação!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async salvarExperiencia(
    row: any,
  ): Promise<void> {
    const route = 'salvarExperiencia';

    const params = {
      requestType: 'Row',
      row,
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err) {
      let message = 'Não foi possível salvar a experiência profissional!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async excluirExperiencia(
    nrExperiencia: string,
  ): Promise<void> {
    const route = 'excluirExperiencia';

    const params = {
      requestType: 'Row',
      row: {
        NREMPREGOANTERIOR: nrExperiencia,
      },
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err) {
      let message = 'Não foi possível excluir a experiência profissional!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async salvarDocumento(
    row: any,
  ): Promise<void> {
    const route = 'salvarDocumento';

    const params = {
      requestType: 'Row',
      row,
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err) {
      let message = 'Não foi possível salvar o documento!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async excluirDocumento(
    nrAnexo: string,
  ): Promise<void> {
    const route = 'excluirDocumento';

    const params = {
      requestType: 'Row',
      row: {
        NRANEXO: nrAnexo,
      },
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err) {
      let message = 'Não foi possível excluir o documento!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async downloadAnexo(
    nrAnexo: string,
  ): Promise<void> {
    const route = 'downloadAnexo';

    const params = {
      requestType: 'Row',
      row: {
        NRANEXO: nrAnexo,
      },
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err) {
      let message = 'Não foi possível carregar o documento!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }
}

export default PerfilController;
