





























































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Util from '../../assets/utils/Util';
import VueUtils from '../../assets/utils/VueUtils';
import HttpRequest from '../../assets/utils/HttpRequest';
import SnackbarUtils from '../../assets/utils/SnackbarUtils';
import EditableTableColumn from '../../assets/interfaces/EditableTableColumn';
import Row from '../../assets/interfaces/EditableTableRow';

import TkSelect from '../fields/TkSelect.vue';
import TkDate from '../fields/TkDate.vue';

@Component({
  name: 'EditableTableField',
  components: {
    TkSelect,
    TkDate,
  }
})
class EditableTableField extends Vue {
  // #region [ PROPS ]
  @Prop({ required: true })
  private column: EditableTableColumn;

  @Prop({ default: false })
  private disabled: boolean | string;

  @Prop({ default: 0 })
  private filters: any;

  @Prop({ default: '' })
  private route: string;

  @Prop({ required: true })
  private row: any;

  @Prop({ required: true })
  private tableMode: string;

  @Prop({ default: 0 })
  private triggerForceUpdate: number;

  @Prop({ default: 0 })
  private triggerReloadField: number;

  @Prop({ default: null })
  private rowStyleFunction: (row: any) => object | string;
  // #endregion

  // #region [ EVENTS ]
  @Emit('blur')
  private emitBlur() {};

  @Emit('click')
  private emitClick(row: any, column: any) {
    return [row, column];
  };

  @Emit('focus')
  private emitFocus() {};
  // #endregion

  // #region [ DATA ]
  private editing = false;

  private defaults = {
    date: {
      label: '',
      dense: true,
      month: false,
    },
  };
  // #endregion

  // #region [ COMPUTED ]
  private get cellStyle() {
    const styleFunction = this.column.style || this.rowStyleFunction;
    const style = styleFunction ? styleFunction(this.row) : undefined;

    return style;
  }

  private get currentType(): string {
    const typeAndModeRelation: any = {
      view: this.viewType,
      edit: this.editType,
      add: this.addType,
    };

    const mode = this.row.__mode;
    const type = typeAndModeRelation[mode];

    return type;
  }

  private get viewType(): string {
    const type =
      this.column.type || 'text';

    return type;
  }

  private get editType(): string {
    const type =
      this.column.editType ||
      this.viewType ||
      'text';

    return type;
  }

  private get addType(): string {
    const type =
      this.column.addType ||
      this.column.editType ||
      'text';

    return type;
  }

  private get isTextType(): boolean {
    const isText =
      this.column.type === 'text' ||
      this.column.type === 'number';

    return isText;
  }

  private get fieldClasses(): any {
    const classes = [
      'pt-0',
      'mb-1',
      this.alignClassName,
    ];

    return classes;
  }

  private get alignClassName(): any {
    const alignmentAndClassRelation: {[index: string]: string} = {
      left: 'editable-table-field--align-left',
      center: 'editable-table-field--align-center',
      right: 'editable-table-field--align-right',
    };

    const alignmentClassName =
      alignmentAndClassRelation[this.column.textAlign] ||
      alignmentAndClassRelation.left;

    return alignmentClassName;
  }

  private get iconColor(): string {
    const columnColor = this.column.color;

    if (!columnColor) {
      return '';
    }

    let color;

    if (typeof columnColor === 'function') {
      color = columnColor(this.row);
    } else {
      color = columnColor;
    }

    return color;
  }

  private get tooltipText(): string {
    const columnTooltip = this.column.tooltip;

    if (!columnTooltip) {
      return '';
    }

    let text: string;

    if (typeof columnTooltip === 'function') {
      text = columnTooltip(this.row);
    } else {
      text = columnTooltip;
    }

    return text;
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('triggerForceUpdate')
  private triggerForceUpdateOnChange() {
    super.$forceUpdate();
    this.updateEditing();
  }

  @Watch('row', {deep: true, immediate: true})
  private rowOnChange() {
    this.updateEditing();
  }

  @Watch('column', {deep: true, immediate: true})
  private columnOnChange() {
    this.updateEditing();
  }
  // #endregion

  // #region [ METHODS ]
  private onInput() {
    super.$emit('input');

    const onInputCallback = this.column.onInput;

    if (typeof onInputCallback === 'function') {
      onInputCallback(this.row);
    }
  }

  private onChange() {
    super.$emit('change');
  }

  private onEnter() {
    super.$emit('enter');
  }

  private onEsc() {
    super.$emit('esc');
  }

  private cellOnClick(row: any, column: any) {
    this.emitClick(row, column);
  }

  private getCellStyle(row: any, column: any): any {
    const styleFunction = column.style;

    const style = styleFunction ? styleFunction(row) : undefined;

    return style;
  }

  private updateEditing() {
    const isEditing =
      this.row.__mode === 'edit' &&
      !this.column.readonly &&
      !this.row.readonly &&
      !Util.isEmptyOrBlank(this.column.type) &&
      this.tableMode === 'edit';

    this.editing = isEditing;
  }

  private onFocus() {
    this.emitFocus();

    const onFocusCallback = this.column.onFocus;

    if (typeof onFocusCallback === 'function') {
      onFocusCallback();
    }
  }

  private onBlur() {
    this.emitBlur();

    const onBlurCallback = this.column.onBlur;

    if (typeof onBlurCallback === 'function') {
      onBlurCallback(this.row);
    }
  }

  private onClick() {
    const onClickCallback = this.column.onClick;

    if (typeof onClickCallback === 'function') {
      onClickCallback();
    }
  }

  private isCellDisabled() {
    return false;
    if (VueUtils.propertyIsTrue(this.disabled)) {
      return true;
    }

    if (this.column.condition) {
      const isDisabled = !this.column.condition(this.row);

      return isDisabled;
    }
  }

  // Colocar no utils
  private useDefaultValueIfEmpty(property: any, defaultValue: any) {
    if (Util.isEmptyOrBlank(property)) {
      return defaultValue
    }

    return property
  }

  private getIcon(row: any, column: any): string {
    let icon = '';

    if (typeof column.icon === 'function') {
      icon = column.icon(row);
    } else {
      icon = column.icon;
    }

    return icon;
  }

  private getColumnDescription(column: any) {
    const value = this.row[column.value];

    if (column.type === 'select' || column.type === 'autocomplete') {
      const { descriptionField, valueField } = column;

      const currentItem = column.items.find((item: any) => {
        return item[valueField] === value;
      });

      if (!currentItem) {
        return undefined;
      }

      const itemDescription = currentItem[descriptionField];

      return itemDescription;
    }

    return value;
  }
  // #endregion
}

export default EditableTableField;
