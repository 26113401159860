import { Vue, Component, Emit } from 'vue-property-decorator';
import Field from '@/../bower_components/teknisavuecomponents/assets/interfaces/Field';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';

import TkForm from '@/../bower_components/teknisavuecomponents/components/TkForm.vue';
import PerfilController from '@/assets/ts/controller/PerfilController';
import ToolbarAction from '@/../bower_components/teknisavuecomponents/assets/interfaces/ToolbarAction';
import MeuPerfilTab from '@/components/meu-perfil/MeuPerfilTab/MeuPerfilTab.vue';
import GeralController from '@/assets/ts/controller/GeralController';

@Component({
  name: 'Endereco',
  components: {
    MeuPerfilTab,
    TkForm,
  },
})
class Endereco extends Vue {
  // #region [ PROPS ]
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('intersection')
  public emitIntersection() {}
  // #endregion

  // #region [ DATA ]
  public row: any = {};
  public backup: any = {};

  private readonly defaultRow = {
    CDPAIS: null as string,
    SGESTADO: null as string,
    CDMUNICIPIO: null as string,
  };

  private fieldsData = {
    paises: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    estados: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    cidades: {
      loading: false,
      error: false,
      items: [] as any[],
    },
    logradouros: {
      loading: false,
      error: false,
      items: [] as any[],
    },
  }

  private loading = {
    reload: false,
    save: false,
  };

  public actions: ToolbarAction[] = [
    {
      name: 'save',
      label: 'Salvar',
      mode: 'edit',
      color: 'green',
      icon: 'mdi-content-save',
      onClick: this.save,
    },
    {
      name: 'cancel',
      label: 'Desfazer alterações',
      mode: 'edit',
      color: 'red',
      icon: 'mdi-close-circle',
      onClick: this.cancel,
    },
  ];
  // #endregion

  // #region [ COMPUTED ]
  public get isLoading(): boolean {
    return Util.anyTrue(Object.values(this.loading));
  }

  public get fields(): Field[] {
    return [
      {
        name: 'NRCEPENDERECO',
        label: 'CEP',
        type: 'text',
        size: 12,
        md: 3,
        mask: '#####-###',
      },
      {
        type: 'empty',
        size: 9,
      },
      {
        name: 'DSENDERECO',
        label: 'Endereço',
        type: 'text',
        size: 12,
        md: 7,
      },
      {
        name: 'NRIMOVELENDERECO',
        label: 'Número',
        type: 'text',
        size: 12,
        md: 2,
      },
      {
        name: 'DSCOMPLEENDERECO',
        label: 'Complemento',
        type: 'text',
        size: 12,
        md: 3,
      },
      {
        name: 'CDPAIS',
        label: 'País',
        type: 'autocomplete',
        size: 12,
        md: 4,
        items: this.fieldsData.paises.items,
        loading: this.fieldsData.paises.loading,
        descriptionField: 'NMPAIS',
        valueField: 'CDPAIS',
        onInput: this.paisOnInput,
      },
      {
        name: 'SGESTADO',
        label: 'Estado',
        type: 'autocomplete',
        size: 12,
        md: 4,
        items: this.fieldsData.estados.items,
        loading: this.fieldsData.estados.loading,
        readonly: this.estadoIsReadonly,
        descriptionField: 'NMESTADO',
        valueField: 'SGESTADO',
        onInput: this.estadoOnInput,
      },
      {
        name: 'CDMUNICIPIO',
        label: 'Cidade',
        type: 'autocomplete',
        size: 12,
        md: 4,
        items: this.fieldsData.cidades.items,
        loading: this.fieldsData.cidades.loading,
        readonly: this.cidadeIsReadonly,
        descriptionField: 'NMMUNICIPIO',
        valueField: 'CDMUNICIPIO',
      },
      {
        name: 'NMBAIRROENDERECO',
        label: 'Bairro',
        type: 'text',
        size: 12,
        md: 6,
      },
      {
        name: 'CDLOGRADOURO',
        label: 'Logradouro',
        type: 'autocomplete',
        items: this.fieldsData.logradouros.items,
        loading: this.fieldsData.logradouros.loading,
        size: 12,
        md: 6,
        valueField: 'CDLOGRADOURO',
        descriptionField: 'DSLOGRADOURO',
      },
    ];
  }

  public get estadoIsReadonly(): boolean {
    const cdPais: string = this.row.CDPAIS;
    return !cdPais;
  }

  public get cidadeIsReadonly(): boolean {
    const cdPais: string = this.row.CDPAIS;
    const sgEstado: string = this.row.SGESTADO;

    return !cdPais || !sgEstado;
  }
  // #endregion

  // #region [ WATCHERS ]
  // #endregion

  // #region [ LIFECYCLE ]
  public async created(): Promise<void> {
    await this.reload();
    this.loadFieldsData();
  }
  // #endregion

  // #region [ METHODS ]
  private async reload(): Promise<void> {
    this.loading.reload = true;

    try {
      this.row = await PerfilController.getEndereco();
      this.setDefaultRow();
    } finally {
      this.loading.reload = false;
    }
  }

  private cancel(): void {
    this.$confirm({
      title: 'Desfazer alterações',
      message: 'Deseja desfazer as alterações não salvas?',
      button: {
        no: 'Cancelar',
        yes: 'Sim',
      },
      callback: (confirm: boolean) => {
        if (confirm) {
          this.row = Util.deepCopy(this.backup);
        }
      },
    });
  }

  private async save(): Promise<void> {
    if (!this.validate()) {
      return;
    }

    this.loading.save = true;

    try {
      await PerfilController.salvarEndereco(this.row);
      this.$toast.success('Alterações salvas com sucesso!');
      this.reload();
    } finally {
      this.loading.save = false;
    }
  }

  private validate(): boolean {
    if (!this.validateForm()) {
      return false;
    }

    return true;
  }

  private validateForm(): boolean {
    const form = this.$refs.form;
    const isValid = (form as any).validate();

    if (!isValid) {
      this.$toast.error('Preencha os campos obrigatórios!');
    }

    return isValid;
  }

  private loadFieldsData(): void {
    this.loadPaises();
    this.loadEstados();
    this.loadCidades();
    this.loadLogradouros();
  }

  private async loadPaises(): Promise<void> {
    const fieldData = this.fieldsData.paises;
    fieldData.loading = true;

    try {
      fieldData.items = await GeralController.getPais();
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadEstados(): Promise<void> {
    const cdPais: string = this.row.CDPAIS;

    if (!cdPais) {
      return;
    }

    const fieldData = this.fieldsData.estados;
    fieldData.loading = true;

    try {
      fieldData.items = await GeralController.getEstado(cdPais);
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadCidades(): Promise<void> {
    const cdPais: string = this.row.CDPAIS;
    const sgEstado: string = this.row.SGESTADO;

    if (!cdPais || !sgEstado) {
      return;
    }

    const fieldData = this.fieldsData.cidades;
    fieldData.loading = true;

    try {
      fieldData.items = await GeralController.getMunicipio(cdPais, sgEstado);
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadLogradouros(): Promise<void> {
    const fieldData = this.fieldsData.logradouros;
    fieldData.loading = true;

    try {
      fieldData.items = await PerfilController.getLogradouro();
    } finally {
      fieldData.loading = false;
    }
  }

  private paisOnInput(): void {
    this.row.SGESTADO = null;
    this.row.CDMUNICIPIO = null;

    this.loadEstados();
    this.loadCidades();
  }

  private estadoOnInput(): void {
    this.row.CDMUNICIPIO = null;
    this.loadCidades();
  }

  private setDefaultRow(): void {
    this.row = {
      ...this.defaultRow,
      ...this.row,
    };
  }
  // #endregion
}

export default Endereco;
