import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import CurrencyUtils from '@/../bower_components/teknisavuecomponents/assets/utils/CurrencyUtils';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';

interface SalaryFilters {
  min?: string;
  max?: string;
}

@Component({
  name: 'SalaryFilter',
  components: {},
})
class SalaryFilter extends Vue {
  // #region [ PROPS ]
  @Prop({ default: null })
  private readonly value: SalaryFilters;
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('input')
  private emitInput() {
    return this.getFiltersToEmit();
  }
  // #endregion

  // #region [ DATA ]
  private readonly defaultValue: SalaryFilters = { min: '', max: '' };

  public show = false;

  public internalValue: SalaryFilters = null;
  public confirmedValue: SalaryFilters = null;
  // #endregion

  // #region [ COMPUTED ]
  private get anyFilterIsSet(): boolean {
    if (this.confirmedValue === null) {
      return false;
    }

    const minIsSet = this.confirmedValue.min !== '';
    const maxIsSet = this.confirmedValue.max !== '';

    const anyFilterIsSet = minIsSet || maxIsSet;

    return anyFilterIsSet;
  }

  public get description(): string {
    let desc = 'Salário';

    if (this.confirmedValue === null) {
      return desc;
    }

    const minIsSet = this.confirmedValue.min !== '';
    const maxIsSet = this.confirmedValue.max !== '';

    if (this.anyFilterIsSet) {
      desc += ': ';

      if (minIsSet) {
        const min = CurrencyUtils.numberToCurrency(this.confirmedValue.min);
        desc += `Min. ${min}`;

        if (maxIsSet) {
          desc += '; ';
        }
      }

      if (maxIsSet) {
        const max = CurrencyUtils.numberToCurrency(this.confirmedValue.max);
        desc += `Max. ${max}`;
      }
    }

    return desc;
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('value', { deep: true, immediate: true })
  private valueOnChange(): void {
    if (!this.value) {
      this.internalValue = Util.deepCopy(this.defaultValue);
      this.confirmedValue = Util.deepCopy(this.defaultValue);
      return;
    }

    if (this.internalValue && Util.deepCompare(this.value, this.getFiltersToEmit())) {
      return;
    }

    const min = this?.value?.min ?? '';
    const max = this?.value?.max ?? '';

    this.internalValue = {
      min,
      max,
    };

    this.confirmedValue = Util.deepCopy(this.internalValue);
  }
  // #endregion

  // #region [ LIFECYCLE ]
  // #endregion

  // #region [ METHODS ]
  public confirmFilters(): void {
    this.confirmedValue = Util.deepCopy(this.internalValue);

    this.closeMenu();
    this.emitInput();
  }

  private closeMenu() {
    this.show = false;
  }

  public resetFilters(): void {
    this.internalValue = Util.deepCopy(this.defaultValue);
    this.confirmedValue = Util.deepCopy(this.defaultValue);
    this.emitInput();
  }

  private getFiltersToEmit(): SalaryFilters {
    if (this.internalValue === null) {
      return null;
    }

    const emitValue = Util.deepCopy(this.internalValue);

    return emitValue;
  }

  public minSalaryOnInput(value: number) {
    this.internalValue.min = value.toString();
  }

  public maxSalaryOnInput(value: number) {
    this.internalValue.max = value.toString();
  }
  // #endregion
}

export default SalaryFilter;
