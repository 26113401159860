import { Vue, Component } from 'vue-property-decorator';
import PerfilController from '@/assets/ts/controller/PerfilController';
import CurriculoPopup from '@/components/meu-perfil/CurriculoPopup/CurriculoPopup.vue';

import DadosPessoais from '@/components/meu-perfil/DadosPessoais/DadosPessoais.vue';
import DadosAdicionais from '@/components/meu-perfil/DadosAdicionais/DadosAdicionais.vue';
import Endereco from '@/components/meu-perfil/Endereco/Endereco.vue';
import Contato from '@/components/meu-perfil/Contato/Contato.vue';
import Formacao from '@/components/meu-perfil/Formacao/Formacao.vue';
import Experiencia from '@/components/meu-perfil/Experiencia/Experiencia.vue';
import Documentos from '@/components/meu-perfil/Documentos/Documentos.vue';

@Component({
  name: 'MeuPerfil',
  components: {
    CurriculoPopup,
    DadosPessoais,
    DadosAdicionais,
    Endereco,
    Contato,
    Formacao,
    Experiencia,
    Documentos,
  },
})
export default class MeuPerfil extends Vue {
  // #region [ DATA ]
  public tab = 0;

  private timeMounted: number = null;

  private lastClickTabTime = 0;

  private loading = {
    resume: false,
    documentos: false,
  };

  public rows = {
    documentos: [] as any[],
    foto: '',
  };

  public mode = 'edit';

  private readonly ids = [
    'pessoais',
    'adicionais',
    'endereco',
    'contato',
    'formacao',
    'experiencia',
    'documentos',
  ];

  public curriculoPopupController = {
    show: false,
    url: '',
  };
  // #endregion

  // #region [ LIFECYCLE ]
  public async created(): Promise<void> {
    window.meuPerfil = this;
  }

  public mounted(): void {
    this.timeMounted = Date.now();
  }
  // #endregion

  // #region [ METHODS ]
  private getTimeSinceLastClickTab(): number {
    const currentTime = Date.now();
    const difference = currentTime - this.lastClickTabTime;

    return difference;
  }

  private getTimeSinceMounted(): number {
    const currentTime = Date.now();
    const difference = currentTime - this.timeMounted;

    return difference;
  }

  public getIntersectionOptions(index: number) {
    const options = {
      handler: () => this.onIntersection(index),
      options: {
        threshold: 0.8,
      },
    };

    return options;
  }

  private tabOnChange(tabNumber: number) {
    if (this.getTimeSinceMounted() < 500) {
      return;
    }

    this.lastClickTabTime = Date.now();
    const id = this.ids[tabNumber];

    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  private onIntersection(tabNumber: number) {
    if (this.getTimeSinceLastClickTab() > 1000 && this.getTimeSinceMounted() > 500) {
      this.tab = tabNumber;
    }
  }

  public async generateResume(): Promise<void> {
    this.loading.resume = true;

    try {
      const link = await PerfilController.generateResume(false);

      this.curriculoPopupController.url = link;
      this.curriculoPopupController.show = true;
    } finally {
      this.loading.resume = false;
    }
  }
  // #endregion
}
