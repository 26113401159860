import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import FileUtils from '@/../bower_components/teknisavuecomponents/assets/utils/FileUtils';
import Dictionary from '@/../bower_components/teknisavuecomponents/assets/interfaces/Dictionary';
import TkVisualizer from '@/../bower_components/teknisavuecomponents/components/document/TkVisualizer/TkVisualizer.vue';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';
import GeralController from '@/assets/ts/controller/GeralController';
import PerfilController from '@/assets/ts/controller/PerfilController';

@Component({
  name: 'DocumentoCard',
  components: {
    TkVisualizer,
  },
})
class DocumentoCard extends Vue {
    // #region [ PROPS ]
    @Prop({ default: true })
    private readonly canOpen: boolean;

    @Prop({ default: null })
    private readonly row: any;
    // #endregion

    // #region [ STORE ]
    // #endregion

    // #region [ EVENTS ]
    @Emit('delete')
    private emitDelete() {}

    @Emit('update:downloading')
    private emitUpdateDownloading() {
      return this.loading.base64;
    }
    // #endregion

    // #region [ DATA ]
    public visualizerController = {
      show: false,
      base64: '',
      mimeType: '',
      fileName: '',
    };

    private loading = {
      base64: false,
      delete: false,
    };

    private clickFromAction = false;
    // #endregion

    // #region [ COMPUTED ]
    public get fileName(): string {
      return this.row ? this.row.NMANEXO : '';
    }

    public get fileReference(): string {
      return this.row ? this.row.DSREFERENCIA : '';
    }

    private get fileType(): string {
      if (!this.fileName) {
        return '';
      }

      const fileType = FileUtils.getFileType(this.fileName);
      return fileType;
    }

    public get tipo(): string {
      return this.row.NMTIPODOCUMENTO || '-';
    }

    public get fileIcon(): string {
      const iconMap: Dictionary = {
        [FileUtils.TYPE_IMAGE]: 'mdi-image',
        [FileUtils.TYPE_DOCUMENT]: 'mdi-file-document',
        [FileUtils.TYPE_EXCEL]: 'mdi-microsoft-excel',
        [FileUtils.TYPE_PPT]: 'mdi-microsft-powerpoint',
        [FileUtils.TYPE_PDF]: 'mdi-file-pdf-box',
        [FileUtils.TYPE_COMPRESSED]: 'mdi-zip-box',
        [FileUtils.TYPE_OTHER]: 'mdi-file-outline',
      };

      const icon = iconMap[this.fileType];

      return icon;
    }

    public get isLoading(): boolean {
      return Util.anyTrue(Object.values(this.loading));
    }

    public get fullBase64(): string {
      const { base64, mimeType } = this.visualizerController;

      if (!this.visualizerController.base64) {
        return '';
      }

      const fullB64 = FileUtils.getFullBase64(base64, mimeType);
      return fullB64;
    }

    public get isImage(): boolean {
      return this.fileType === FileUtils.TYPE_IMAGE;
    }

    public get showSkeletonLoader(): boolean {
      const show = this.loading.base64
        || !this.isImage
        || !this.visualizerController.base64;

      return show;
    }
    // #endregion

    // #region [ WATCHERS ]
    // #endregion

    // #region [ LIFECYCLE ]
    // #endregion

    // #region [ METHODS ]
    private async delete(): Promise<void> {
      this.loading.delete = true;

      const nrAnexo: string = this.row.NRANEXO;

      try {
        await PerfilController.excluirDocumento(nrAnexo);

        this.$toast.success('Documento excluido com sucesso');
        this.emitDelete();
      } finally {
        this.loading.delete = false;
      }
    }

    public async view(): Promise<void> {
      if (!this.canOpen) {
        return;
      }

      if (!this.visualizerController.base64) {
        await this.fetchBase64();

        this.visualizerController.mimeType = await FileUtils.getBase64MimeType(
          this.visualizerController.base64,
        );
      }

      this.visualizerController.fileName = this.row.NMANEXO;
      this.visualizerController.show = true;
    }

    private async fetchBase64(): Promise<void> {
      this.loading.base64 = true;
      const nrAnexo: string = this.row.NRANEXO;

      this.emitUpdateDownloading();

      try {
        const file = await GeralController.downloadAnexo(nrAnexo);
        this.visualizerController.base64 = file.base64;
        this.visualizerController.mimeType = file.type;
      } finally {
        this.loading.base64 = false;
        this.emitUpdateDownloading();
      }
    }

    public cardOnClick(): void {
      if (this.clickFromAction) {
        this.clickFromAction = false;
        return;
      }

      this.view();
    }

    public deleteOnClick(): void {
      this.clickFromAction = true;

      this.$confirm({
        title: 'Excluir Documento',
        message: 'Deseja realmente excluir o documento?',
        button: {
          yes: 'Sim',
          no: 'Cancelar',
        },
        callback: (confirm) => {
          if (confirm) {
            this.delete();
          }
        },
      });
    }
    // #endregion
}

export default DocumentoCard;
