





























































































































import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Util from '../../assets/utils/Util';
import HttpRequest from '../../assets/utils/HttpRequest';
import SnackbarUtils from '../../assets/utils/SnackbarUtils';
import Column from '../../assets/interfaces/EditableTableColumn';
import Row from '../../assets/interfaces/EditableTableRow';
import VueUtils from '../../assets/utils/VueUtils';
import RowUtils from '../../assets/utils/RowUtils';

import EditableTableField from './EditableTableField.vue';
import TkToolbar from './TkToolbar.vue';

import ConfirmationDialog from '../popups/ConfirmationDialog.vue';

interface ToolbarAction {
  icon: string;
  name: string;
  label: string;
  returnEditedRows: boolean;
  onClick: (rows: any) => void;
}

@Component({
  name: 'EditableTable',
  components: {
    EditableTableField,
    TkToolbar,
    ConfirmationDialog,
  },
})
class EditableTable extends Vue {
  // #region [ PROPS ]
  @Prop({ default: (): any => [] })
  private readonly actions: any[];

  @Prop({ default: null })
  private readonly actionsIndex: number;

  @Prop({ default: false })
  private readonly cancelRequest: boolean;

  @Prop({ required: true })
  private readonly columns: Column[];

  @Prop({ default: null })
  private readonly customBackup: any[];

  @Prop({ default: () => {} })
  private readonly defaultRow: any;

  @Prop({ default: false })
  private readonly dense: boolean;

  @Prop({ default: false })
  private readonly disabled: boolean;

  @Prop({ default: false })
  private readonly disableSort: boolean;

  @Prop({ default: () => {} })
  private readonly filters: any;

  @Prop({ })
  private readonly footerRow: (tableRows: any) => any;

  @Prop({ default: false })
  private readonly hideDefaultFooter: boolean | string;

  @Prop({ default: 10 })
  private readonly itemsPerPage: number;

  @Prop({ default: false })
  private readonly loading: boolean;

  @Prop({ default: 'Carregando itens...' })
  private readonly loadingText: string;

  @Prop({ default: 0 })
  private readonly mobileBreakpoint: number;

  @Prop({ default: 'view' })
  private readonly mode: string;

  @Prop({ default: 'Nenhum item encontrado.' })
  private readonly noDataText: string;

  @Prop({ default: undefined })
  private readonly onEnterAction: any;

  @Prop({ default: undefined })
  private readonly onEscAction: any;

  @Prop({ default: 1 })
  private readonly page: number;

  @Prop({ default: false })
  private readonly reloadOnCreation: boolean;

  @Prop({ default: '' })
  private readonly route: string;

  @Prop({ default: (): any => [] })
  private readonly rows: Row[];

  @Prop({ default: () => [] })
  private readonly rowFilters: ((row) => boolean)[];

  @Prop({ default: () => {} })
  private readonly rowStyleFunction: (row: any) => object | string;

  @Prop({ default: 4 })
  private readonly searchSize: number | string;

  @Prop({ default: false })
  private readonly singleLine: boolean;

  @Prop({ default: false })
  private readonly showSearch: boolean;

  @Prop({ default: true })
  private readonly showToolbar: boolean;

  @Prop({ default: (): any => [] })
  private readonly toolbarActions: any[];

  @Prop({ default: 0 })
  private readonly triggerAddRow: number;

  @Prop({ default: 0 })
  private readonly triggerForceUpdate: number;

  @Prop({ default: 0 })
  private readonly triggerReload: number;
  // #endregion

  // #region [ EVENTS ]
  @Emit('after-add')
  private emitAfterAdd() {};

  @Emit('after-reload')
  private emitAfterReload(rows: any[]) {};

  @Emit('before-add')
  private emitBeforeAdd() {};

  @Emit('click:cell')
  private emitClickCell(row: any, column: any) {
    return [row, column];
  };

  @Emit('click:row')
  private emitClickRow(row: any) {};

  @Emit('edit')
  private emitEdit() {};

  @Emit('error')
  private emitError(error: Error) {};

  @Emit('field-blur')
  private emitFieldBlur(row: any, column: any) {
    return [row, column];
  };

  @Emit('field-focus')
  private emitFieldFocus(row: any, column: any) {
    return [row, column];
  };

  @Emit('update:cancel-request')
  private emitUpdateCancelRequest(cancelRequest: boolean) {}

  @Emit('update:page')
  private emitUpdatePage(page: number) {}

  @Emit('update:rows')
  private emitUpdateRows(rows: any[]) {}
  // #endregion

  // #region [ DATA ]
  private tableRows: any[] = [];
  private tableColumns: Column[] = [];

  private tableLoading = false;

  private backup: any = [];
  private nextId = 0;

  private searchValue = '';

  private defaultActions = {
    edit: {
      name: 'edit',
      label: 'Editar',
      icon: 'mdi-pencil',
      onClick: this.edit,
      mode: 'view',
    },
    delete: {
      name: 'delete',
      label: 'Excluir',
      icon: 'mdi-delete',
      onClick: this.delete,
      mode: 'view',
    },
    save: {
      name: 'save',
      label: 'Salvar',
      icon: 'mdi-content-save',
      onClick: this.save,
      mode: 'edit',
    },
    cancel: {
      name: 'cancel',
      label: 'Cancelar',
      icon: 'mdi-cancel',
      onClick: this.cancel,
      mode: 'edit',
    },
    ERROR: {
      name: 'ERROR',
      label: 'ERROR',
      icon: 'mdi-error',
      onClick: () => {},
      mode: 'view',
    }
  };

  private defaultToolbarActions = {
    add: {
      icon: 'mdi-plus-circle',
      name: 'add',
      label: 'Adicionar',
      onClick: this.addRow
    },
    reload: {
      icon: 'mdi-refresh',
      name: 'reload',
      label: 'Atualizar',
      onClick: this.reload
    },
    cancel: {
      icon: 'mdi-cancel',
      name: 'cancel',
      label: 'Cancelar Alterações',
      returnEditedRows: false,
      onClick: this.showCancelRowChangesDialog,
    },
  };

  private triggerUpdateFields = 0;

  private currentRequestCode = 0;

  private internalCancelRequest = false;

  private confirmationDialog = {
    isVisible: false,
    acceptText: 'Sim',
    declineText: 'Não',
    message: '',
    acceptedDialog: (): any => null,
    position: {
      middle: true
    }
  };

  private messages = {
    cancelRowChanges: 'Deseja realmente cancelar as alterações?'
  };

  private blockWatcher: any = {
    rows: false,
  };
  // #endregion

  // #region [ COMPUTED ]
  get isReadOnly(): boolean {
    const readOnly = this.mode === 'view';

    return readOnly;
  }

  get tableActions() : any[] {
    const actions = this.actions.map(action => {
      let newAction;

      const actionIsString = typeof action === 'string';

      const actionName = actionIsString ?
        action :
        action.name;

      const defaultAction = (this.defaultActions as any)[actionName];

      if (!defaultAction) {
        if (actionIsString) {
          const errorMessage = `A action "${action}" não existe!`;
          SnackbarUtils.showMessage(errorMessage, 'error', 5000);

          newAction = this.defaultActions.ERROR;
        } else {
          newAction = action;
        }
      } else {
        if (actionIsString) {
          newAction = defaultAction;
        } else {
          newAction = {...defaultAction, ...action};
        }
      }

      return newAction;
    });

    return actions;
  }

  private get tableToolbarActions() {
    const actions = this.toolbarActions.map(action => {
      const tbAction = typeof action === 'string' ?
        (this.defaultToolbarActions as any)[action] :
        action;

      return tbAction;
    });

    return actions;
  }

  private get dataTableRows(): any[] {
    const rows = this.tableRows.filter((row: any) => !row.__footer);

    return rows;
  }

  private get propSingleLine(): boolean {
    const singleLine = VueUtils.propertyIsTrue(this.singleLine);

    return singleLine;
  }

  private get propReloadOnCreation(): boolean {
    const reloadOnCreation = VueUtils.propertyIsTrue(this.reloadOnCreation);

    return reloadOnCreation;
  }

  private get internalShowToolbar(): boolean {
    const show =
      this.showToolbar &&
      // se conferir direto o length, vai dar erro qnd n tiver toolbar action
      this.toolbarActions &&
      this.toolbarActions.length > 0;

      return show;
  }

  private get isLoading(): boolean {
    return this.loading || this.tableLoading;
  }

  private get internalFooterRow(): any {
    const footer = this.tableRows.find(
      (row: any) => row.__footer === true);

    return footer;
  }

  private get visibleColumns(): any[] {
    const columns = this.columns.filter((col: any) => {
      const isVisible =
        col.isVisible === 'true' ||
        col.isVisible === true ||
        col.isVisible === undefined;

      return isVisible;
    });

    return columns;
  }

  private get showSearchProp(): boolean {
    const propValue = VueUtils.propertyIsTrue(this.showSearch);

    return propValue;
  }

  private get rowsAreSet(): boolean {
    const areSet = this.tableRows.reduce((accumulator, row: any) => {
      const rowIsSet =
        row.__id !== undefined
        row.__id !== undefined;

      return accumulator && rowIsSet;
    }, true);

    return areSet;
  }

  private get tableRowsFiltered(): any {
    if (Util.isEmptyOrBlank(this.rowFilters)) {
      return this.tableRows;
    }

    return this.rowFilters.reduce(((acc, cur) => acc = this.tableRows.filter(cur)), this.tableRows);
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('rows', {immediate: true, deep: true})
  private rowsOnChange() {
    if (this.tableRows === this.rows && this.rowsAreSet) {
      return;
    }

    this.tableRows = this.rows;

    if (this.footerRow) {
      this.updateFooterRow();
    }

    this.createTableRows();
    this.activateAllEditableRows();
  }

  @Watch('cancelRequest', {immediate: true})
  private cancelRequestOnChange() {
    this.internalCancelRequest = this.cancelRequest;
  }

  @Watch('columns', {deep: true, immediate: true})
  private columnsOnChange() {
    this.createTableColumns();
  }

  @Watch('tableRows', {deep: true, immediate: true})
  private tableRowsOnChange() {
    this.orderColumns();
    this.configActionColumn();
  }

  @Watch('triggerAddRow')
  private triggerAddRowOnChange() {
    this.addRow();
  }

  @Watch('triggerReload')
  private triggerReloadOnChange() {
    this.reload();
  }

  @Watch('triggerForceUpdate')
  private triggerForceUpdateOnChange() {
    super.$forceUpdate();
  }

  @Watch('footerRow', {deep: true})
  private footerRowOnChange() {
    this.updateFooterRow();
  }

  @Watch('actions', {deep: true})
  private actionsOnChange() {
    this.orderColumns();
    this.configActionColumn();
  }

  @Watch('mode', {immediate: true})
  private modeOnChange() {
    this.activateAllEditableRows();

    this.updateFields();
  }
  // #endregion

  // #region [ LIFECYCLE ]
  private async mounted() {
    this.updateFields();

    if (this.propReloadOnCreation) {
      this.reload();
    }
  }
  // #endregion

  // #region [ METHODS ]
  private getSlotName(col: any): string {
    return `item.${col.value}`;
  }

  private fieldIsReadOnly(row: any, column: any): boolean {
    const isReadOnly =
      this.rowIsReadOnly(row) ||
      column.readonly === true;

    return isReadOnly;
  }

  private rowIsReadOnly(row: any): boolean {
    const isReadOnly =
      this.mode === 'view' ||
      row.readonly === true;

    return isReadOnly;
  }

  private fieldOnInput(row: any, column: Column) {
    // Compare to backup to see if there is any changes
    const tableRow = this.tableRows.find((row1: any) => row.__id === row1.__id);

    if (tableRow) {
      tableRow.__changed = RowUtils.rowHasChanges(row);
    }

    this.setOutData(tableRow, column);

    const dataTableRowsCopy = Util.deepCopy(this.dataTableRows);

    super.$emit('field-input', row, column, dataTableRowsCopy);
    this.emitUpdateRows(dataTableRowsCopy);

    if (this.footerRow) {
      this.updateFooterRow();
    }

  }

  private setOutData(
    row: any,
    column: Column
  ) {
    const outDataTypes = [
      'select',
      'autocomplete',
    ];

    if (!outDataTypes.includes(column.type)) {
      return;
    }

    const hasOutData = !!column.outData;
    const value = row[column.value];

    if (!hasOutData) {
      return;
    }

    const outDataKeys = Object.keys(column.outData);
    const selected = Util.findSelectedItem(column, value);

    outDataKeys.forEach((key) => {
      const keyValue = column.outData[key];

      const selectedValue = selected === undefined ?
        undefined :
        selected[keyValue];

      row[key] = selectedValue;
    });
  }

  private fieldOnChange(row: any, column: any) {
    const dataTableRowsCopy = Util.deepCopy(this.dataTableRows);

    super.$emit('field-change', row, column, dataTableRowsCopy);
    this.emitUpdateRows(dataTableRowsCopy);

    if (this.footerRow) {
      this.updateFooterRow();
    }

    // Compare to backup to see if there is any changes
    // Comentado porque essa validação tem que ser feita apenas no input
    //row.__changed = RowUtils.rowHasChanges(row);
  }

  private edit(row : any) {
    this.emitEdit();

    if (this.propSingleLine) {
      this.deactivateAllRows();
    }

    row.__mode = 'edit';
    row.__backup_row = Util.deepCopy(row);
    this.updateFields();

    const dataTableRowsCopy = Util.deepCopy(this.dataTableRows);

    super.$emit('edit', row, dataTableRowsCopy);
    this.emitUpdateRows(dataTableRowsCopy);
  }

  private delete(row : any) {
    const rowIndex = Util.indexOf(this.tableRows,
        (x: any) => x.__id === row.__id);

    this.tableRows.splice(rowIndex, 1);
    this.updateFields();

    const dataTableRowsCopy = Util.deepCopy(this.dataTableRows);

    super.$emit('delete', row, dataTableRowsCopy);
    this.emitUpdateRows(dataTableRowsCopy);
  }

  private save(row : any) {
    const rowBackup = row.__backup_row;
    const rowBackupCopy = Util.deepCopy(rowBackup);

    Object.keys(row).forEach((key) => {
      rowBackupCopy[key] = row[key];
    });

    row.__mode = 'view';
    this.updateFields();

    const dataTableRowsCopy = Util.deepCopy(this.dataTableRows);

    super.$emit('save', row, dataTableRowsCopy);
    // rows não sofrem mudança; portanto, não é necessario emitir update:rows

    // Update row changed
    // The changed information in inline save have no functionality by now. The 'changed' information is used to return the changed rows to other actions
    row.__changed = false;
  }

  private cancel(row : any) {
    const rowBackup = row.__backup_row;

    if (row.__is_new) {
      Util.arrayRemoveCondition(this.tableRows,
        (item: any) => item.__id === row.__id);
    } else {
      const rowBackupCopy = Util.deepCopy(rowBackup);

      Object.keys(rowBackupCopy).forEach((key) => {
        row[key] = rowBackupCopy[key];
      });

      row.__mode = 'view';
      this.updateFields();
    }

    const dataTableRowsCopy = Util.deepCopy(this.dataTableRows);

    // Update row changed
    row.__changed = false;

    super.$emit('cancel', row, dataTableRowsCopy);
    this.emitUpdateRows(dataTableRowsCopy);
    this.updateFooterRow();
  }

  private getRowActions(row: any): any[] {
    const currentModeActions =
      this.tableActions.filter(x => x.mode === row.__mode);

    const filteredActions = currentModeActions.filter((action: any) => {
      if (Util.isUndefinedOrNull(action.condition)) {
        return true;
      }

      if (typeof action.condition === 'boolean') {
        return action.condition;
      }

      const isVisible = action.condition(row, this.dataTableRows);
      return isVisible;
    });

    return filteredActions;
  }

  private createTableRows() {
    this.tableRows.forEach((row: any) => {
      if (!row.__mode) {
        row.__mode = this.propSingleLine || this.mode === 'view' ?
          'view' :
          'edit';
      }

      row.__is_new = row.__is_new || false;
      row.readonly = row.readonly || false;
      row.__backup_row = row.__backup_row || Util.deepCopy(row);

      // Define rows as not changed
      if (row.__changed === undefined) {
        row.__changed = false;
      }

      if (!row.__id) {
        row.__id = this.newId();
      }

      this.columns.forEach((column: Column) => {
        this.setOutData(row, column);
      })
    });
  }

  private createTableColumns() {
    this.tableColumns = Util.deepCopy(this.visibleColumns);

    this.tableColumns.forEach((column: any) => {
      column.readonly = column.readonly || false;
    });
  }

  private configActionColumn() {
    const maxRowActions = this.getMaxRowActions();
    const actionsWidth = Math.max(60, maxRowActions * 40);

    const actionColumnIndex = this.tableColumns.findIndex((column) => {
      return column.value === 'actions';
    });

    if (maxRowActions === 0) {
      Util.arrayRemoveCondition(this.tableColumns, (column) => {
        return column.value === 'actions';
      });

      return;
    }

    // Remove action column for sorting
    if (~actionColumnIndex) {
      this.tableColumns.splice(actionColumnIndex, 1);
    }

    const actionsItem = {
      text: 'Ações',
      value: 'actions',
      align: 'center',
      readonly: true,
      width: actionsWidth,
    }

    // Insert action in a specific index if provided
    if (!Util.isEmptyOrBlank(this.actionsIndex)) {
      this.tableColumns.splice(this.actionsIndex, 0, actionsItem);
      return;
    }

    this.tableColumns.push(actionsItem);
  }

  private orderColumns() {
    const orderProp = VueUtils.getBreakpoint() == 'xs' ? 'orderMobile' : 'order';

    this.tableColumns.sort((a, b) => { return (a[orderProp] ? a[orderProp] : 0) - (b[orderProp] ? b[orderProp] : 0)});
  }

  private async reload() {
    if (!this.route) {
      return;
    }

    const params = {
      requestType: 'FilterData',
      ...this.filters,
    };

    const requestCode = this.getNextRequestCode();

    this.internalCancelRequest = false;
    this.emitUpdateCancelRequest(this.internalCancelRequest);

    try {
      this.tableLoading = true;

      const response = await HttpRequest.get(this.route, params, false);

      if (requestCode !== this.currentRequestCode) {
        return;
      }

      if (this.internalCancelRequest) {
        this.tableLoading = false;
        return;
      }

      const rows =
        response.data.dataset.data ||
        response.data.dataset[this.route] ||
        [];

      Util.redefineArray(this.tableRows, rows);

      if (this.footerRow) {
        this.updateFooterRow();
      }

      this.createTableRows();

      const dataTableRowsCopy = Util.deepCopy(this.dataTableRows);

      this.emitUpdateRows(dataTableRowsCopy);
      this.emitAfterReload(dataTableRowsCopy);
      this.updateFields();
    } catch (err) {
      this.emitError(err);
    }

    this.tableLoading = false;
  }

  // get Action inerface
  private async toolbarActionOnClick(action: any) {
    if (action.returnEditedRows) {
      action.onClick(this.getChangedRows());
    }
  }

  private addRow() {
    this.emitBeforeAdd();

    const newRow = this.generateNewRow();

    const index = this.footerRow ?
      this.tableRows.length - 1 :
      this.tableRows.length;

    if (this.propSingleLine) {
      this.deactivateAllRows();
    }

    this.tableRows.splice(index, 0, newRow);
    this.emitAfterAdd();
  }

  private generateNewRow() {
    const id = this.newId();

    const defaultNewRow = Util.deepCopy(RowUtils.NEW_ROW_DEFAULT_CONTROL_PROPERTIES);

    const newRow = {
      ...defaultNewRow,
      ...this.defaultRow,
    };

    newRow.__id = id;
    newRow.__backup_row = Util.deepCopy(newRow);

    return newRow;
  }

  private cellOnClick(row : any, column : any) {
    const readOnly = this.fieldIsReadOnly(row, column);

    if (!readOnly && this.propSingleLine && row.__mode !== 'edit') {
      this.edit(row);

      setTimeout(() => {
        this.updateFields();

      }, 100);
    }

    if (column.condition) {
      const condition = column.condition(row, this.dataTableRows);
      if (!condition) {
        return;
      }
    }

    this.emitClickCell(row, column);
  }

  private fieldOnEnter(row : any, column : any) {
    if (this.isLoading) {
      return;
    }

    if (this.onEnterAction) {
      this.onEnterAction(row, column, this.tableRows);
    } else {
      this.save(row);
    }
  }

  private fieldOnEsc(row: any, column: any) {
    if (this.onEscAction) {
      this.onEscAction(row, column, this.tableRows);
    } else {
      this.cancel(row);
    }
  }

  private activateAllEditableRows() {
    if (this.propSingleLine) {
      return;
    }

    const editableRows = this.tableRows.filter((row: any) =>
      !this.rowIsReadOnly(row));

    editableRows.forEach((row: any) => row.__mode = 'edit');
  }

  private deactivateAllRows() {
    this.tableRows.forEach((row: any) => row.__mode = 'view');
  }

  private updateFields() {
    this.triggerUpdateFields++;
  }

  private getNextRequestCode(): number {
    this.currentRequestCode++;
    return this.currentRequestCode;
  }

  private fieldOnFocus(row: any, column: any) {
    const rowCopy = Util.deepCopy(row);
    const columnCopy = Util.deepCopy(column);

    this.emitFieldFocus(rowCopy, columnCopy);
  }

  private fieldOnBlur(row: any, column: any) {
    const rowCopy = Util.deepCopy(row);
    const columnCopy = Util.deepCopy(column);

    this.emitFieldBlur(rowCopy, columnCopy);
  }

  private newId(): number {
    this.nextId++;

    return this.nextId;
  }

  private updateFooterRow() {
    const newFooter = this.footerRow(this.tableRows);

    if (newFooter) {
      newFooter.__footer = true;
      newFooter.readOnly = true;

      if (this.internalFooterRow) {
        Util.replaceItem(newFooter, this.tableRows,
          (item: any) => item.__footer === true);
      } else {
        this.tableRows.push(newFooter);
      }
    }
  }

  // Method responsible for return the changed rows
  private getChangedRows() {
    const tableRows = this.tableRows;

    return tableRows.filter((row: any) => row.__changed && !row.__footer);
  }

  // Method responsible for undo changes made on rows
  private cancelRowChanges() {
    const backupRows = Util.deepCopy(this.backup);

    this.tableRows = backupRows;
  }

  private showCancelRowChangesDialog() {
    this.setConfirmationDialogVisibility(true, this.messages.cancelRowChanges, this.cancelRowChanges);
  }

  private setConfirmationDialogVisibility(visibility: boolean, message: string, callback: () => void) {
    this.confirmationDialog.isVisible = visibility;
    this.confirmationDialog.message = message;
    this.confirmationDialog.acceptedDialog = callback;
  }

  private watcherIsBlocked(watcherName: string): boolean {
    const blocked = this.blockWatcher[watcherName] === true;

    return blocked;
  }

  private getMaxRowActions(): number {
    const max = this.tableRows.reduce((accumulator: number, row: any) => {
      const rowActionsLength = this.getRowActions(row).length;

      if (rowActionsLength > accumulator) {
        accumulator = rowActionsLength;
      }

      return accumulator;
    }, 0);

    return max;
  }

  public restoreCustomBackup() {
    const backupCopy = Util.deepCopy(this.customBackup);

    this.tableRows = backupCopy;
    this.createTableRows();

    this.emitUpdateRows(this.tableRows);
  }
  // #endregion
}

export default EditableTable;
