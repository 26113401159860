import { Vue, Component, Emit } from 'vue-property-decorator';
import PerfilController from '@/assets/ts/controller/PerfilController';

import DocumentoCard from '../DocumentoCard/DocumentoCard.vue';
import MeuPerfilTab from '../MeuPerfilTab/MeuPerfilTab.vue';
import NovoDocumentoPopup from '../NovoDocumentoPopup/NovoDocumentoPopup.vue';

import Dictionary from '@/../bower_components/teknisavuecomponents/assets/interfaces/Dictionary';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';

import TkToolbar from '@/../bower_components/teknisavuecomponents/components/grids/TkToolbar.vue';

import ToolbarAction from '@/../bower_components/teknisavuecomponents/assets/interfaces/ToolbarAction';

@Component({
  name: 'Documentos',
  components: {
    DocumentoCard,
    MeuPerfilTab,
    NovoDocumentoPopup,
    TkToolbar,
  },
})
class Documentos extends Vue {
  // #region [ PROPS ]
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('intersection')
  public emitIntersection() {}
  // #endregion

  // #region [ DATA ]
  public loading = false;

  public showPopup = false;

  public categoryGroups: any[] = [];

  private cardsDownloading: Dictionary<boolean> = {};
  public anyIsDownloading = false;

  public actions: ToolbarAction[] = [
    {
      name: 'reload',
      label: 'Adicionar',
      icon: 'mdi-refresh',
      onClick: this.reload,
    },
    {
      name: 'add',
      label: 'Adicionar',
      icon: 'mdi-plus-circle',
      color: 'blue',
      onClick: this.add,
    },
  ];
  // #endregion

  // #region [ COMPUTED ]
  // #endregion

  // #region [ WATCHERS ]
  // #endregion

  // #region [ LIFECYCLE ]
  public created(): void {
    this.reload();
  }
  // #endregion

  // #region [ METHODS ]
  private async reload(): Promise<void> {
    this.loading = true;

    try {
      const rows = await PerfilController.getDocumentos();

      const keys = ['NRCATEGDOCUMENTO', 'NMCATEGDOCUMENTO'];
      this.categoryGroups = Util.betterGroupBy(rows, keys);
    } finally {
      this.loading = false;
    }
  }

  public onSave(): void {
    this.reload();
  }

  public add(): void {
    this.showPopup = true;
  }

  public onDelete(): void {
    this.reload();
  }

  public cardOnUpdateDownloading(row: any, value: boolean): void {
    this.cardsDownloading[row.NRANEXO] = value;
    this.anyIsDownloading = Util.anyTrue(Object.values(this.cardsDownloading));
  }

  public getGroupName(
    group: Dictionary,
  ): string {
    if (group.NRCATEGDOCUMENTO === null) {
      return '(Sem categoria)';
    }

    return `${group.NRCATEGDOCUMENTO} - ${group.NMCATEGDOCUMENTO}`;
  }
  // #endregion
}

export default Documentos;
