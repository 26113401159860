import ToolbarAction from '../../../assets/interfaces/ToolbarAction';
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';

import FileUtils from '../../../assets/utils/FileUtils';
import TkToolbar from '../../grids/TkToolbar.vue';
import VueUtils from '../../../assets/utils/VueUtils';
import pdf from 'vue-pdf';

@Component({
  name: 'TkVisualizer',
  components: {
    TkToolbar,
    pdf,
  },
})
class TkVisualizer extends Vue {
  // #region [ PROPS ]
  @Prop({ default: '' })
  private readonly base64: string;

  @Prop({ default: '' })
  private readonly fileName: string;

  @Prop({ default: '' })
  private readonly mimeType: string;

  @Prop({ default: false })
  private readonly showDownload: boolean | string;

  @Prop({ default: false })
  private readonly value: boolean;
  // #endregion

  // #region [ EVENTS ]
  @Emit('input')
  private emitInput(value: boolean) {}
  // #endregion

  // #region [ DATA ]
  private internalValue = false;

  private loading = false;

  private downloadAction: ToolbarAction = {
    name: 'download',
    label: 'Baixar',
    icon: 'mdi-download',
    onClick: this.download,
  };
  // #endregion

  // #region [ COMPUTED ]
  private get isImage(): boolean {
    const type = this.mimeType.split('/')[0];
    const isImage = type === 'image';

    return isImage;
  }

  private get actions(): ToolbarAction[] {
    let actions: ToolbarAction[] = [];

    if (this.showDownloadProp) {
      actions = [
        this.downloadAction,
      ];
    }

    return actions;
  }

  private get fullBase64(): string {
    if (!this.base64) {
      return '';
    }

    const base64 = FileUtils.getFullBase64(this.base64, this.mimeType);
    return base64;
  }

  private get showDownloadProp(): boolean {
    return VueUtils.propertyIsTrue(this.showDownload);
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('value', { immediate: true })
  private valueOnChange() {
    this.internalValue = this.value;
  }
  // #endregion

  // #region [ METHODS ]
  private closeDialog() {
    this.internalValue = false;
    this.emitInput(this.internalValue);
  }

  private decodeTextFile(): string {
    const decodedFile = FileUtils.decodeBase64(this.base64);

    return decodedFile;
  }

  private async download() {
    const fileName =
      this.fileName ||
      Date.now().toString(10);

    this.loading = true;

    setTimeout(() => {
      this.loading = false;
    }, 5000);

    FileUtils.downloadBase64File(fileName, this.base64, this.mimeType);
  }
  // #endregion
}

export default TkVisualizer;