import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';

import Field from '@/../bower_components/teknisavuecomponents/assets/interfaces/Field';
import TkForm from '@/../bower_components/teknisavuecomponents/components/TkForm.vue';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';
import FileUtils from '@/../bower_components/teknisavuecomponents/assets/utils/FileUtils';
import PerfilController from '@/assets/ts/controller/PerfilController';
import DocumentoController from '@/assets/ts/controller/DocumentoController';

@Component({
  name: 'NovoDocumentoPopup',
  components: {
    TkForm,
  },
})
class NovoDocumentoPopup extends Vue {
  // #region [ PROPS ]
  @Prop({ default: false })
  private readonly value: boolean;
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('input')
  private emitInput() {
    return this.internalValue;
  }

  @Emit('save')
  private emitSave() {}
  // #endregion

  // #region [ DATA ]
  public loading = {
    save: false,
    file: false,
  };

  private fieldsLoading = {
    categorias: false,
    tipos: false,
  };

  public internalValue = false;

  public row: any = {};

  private base64 = '';

  private fieldsData = {
    categorias: null as any[],
    tipos: null as any[],
  };
  // #endregion

  // #region [ COMPUTED ]
  public get isLoading(): boolean {
    return Util.anyTrue(Object.values(this.loading));
  }

  public get fields(): Field[] {
    return [
      {
        name: 'DSREFERENCIA',
        label: 'Descrição',
        type: 'text',
        size: 12,
        required: true,
      },
      {
        name: 'NRCATEGDOCUMENTO',
        label: 'Categoria',
        type: 'autocomplete',
        descriptionField: 'NMCATEGDOCUMENTO',
        valueField: 'NRCATEGDOCUMENTO',
        items: this.fieldsData.categorias,
        size: 6,
        required: true,
        loading: this.fieldsLoading.categorias,
      },
      {
        name: 'NRTIPODOCUMENTO',
        label: 'Tipo',
        type: 'autocomplete',
        descriptionField: 'NMTIPODOCUMENTO',
        valueField: 'NRTIPODOCUMENTO',
        items: this.fieldsData.tipos,
        size: 6,
        required: true,
        loading: this.fieldsLoading.tipos,
      },
      {
        name: 'FILE',
        label: 'Arquivo',
        type: 'file',
        size: 12,
        onInput: this.fileOnChange,
        required: true,
      },
    ] as Field[];
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('value', { immediate: true })
  private valueOnChange(): void {
    this.internalValue = this.value;

    if (this.internalValue) {
      this.row = {};

      this.loadFields();
    }
  }
  // #endregion

  // #region [ LIFECYCLE ]
  // #endregion

  // #region [ METHODS ]
  public closeDialog(): void {
    this.internalValue = false;
    this.emitInput();
  }

  public async save(): Promise<void> {
    if (!this.validate()) {
      return;
    }

    this.loading.save = true;

    try {
      const row = {
        ...this.row,
        FILE: this.base64,
      };

      await PerfilController.salvarDocumento(row);
      this.$toast.success('Documento salvo com sucesso!');

      this.closeDialog();
      this.emitSave();
    } finally {
      this.loading.save = false;
    }
  }

  private validate(): boolean {
    if (!this.validateForm()) {
      return false;
    }

    return true;
  }

  private validateForm(): boolean {
    const form: any = this.$refs.form;
    const isValid = form.validate();

    if (!isValid) {
      this.$toast.error('Preencha todos os campos obrigatórios!');
    }

    return isValid;
  }

  private async fileOnChange(value: any) {
    if (value instanceof File) {
      await this.createBase64File(value);
      this.row.NMANEXO = value.name;
    } else {
      this.base64 = '';
      this.row.NMANEXO = null;
    }
  }

  private async createBase64File(file: File) {
    this.loading.file = true;

    try {
      const fileString = await FileUtils.toBase64(file);
      this.base64 = fileString;
    } catch (err) {
      const message = 'Não foi possível carregar o arquivo';
      this.$toast.warning(message);

      throw err;
    } finally {
      this.loading.file = false;
    }
  }

  private loadFields(): void {
    this.loadCategorias();
    this.loadTipos();
  }

  private async loadCategorias(): Promise<void> {
    if (this.fieldsData.categorias) {
      return;
    }

    this.fieldsLoading.categorias = true;

    try {
      this.fieldsData.categorias = await DocumentoController.getCategorias();
    } finally {
      this.fieldsLoading.categorias = false;
    }
  }

  private async loadTipos(): Promise<void> {
    if (this.fieldsData.tipos) {
      return;
    }

    this.fieldsLoading.tipos = true;

    try {
      this.fieldsData.tipos = await DocumentoController.getTipos();
    } finally {
      this.fieldsLoading.tipos = false;
    }
  }
  // #endregion
}

export default NovoDocumentoPopup;
