import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import DateUtil from '@/../bower_components/teknisavuecomponents/assets/utils/DateUtil';

@Component({
  name: 'ValidDateFilter',
  components: {},
})
class ValidDateFilter extends Vue {
  // #region [ PROPS ]
  @Prop({ default: null })
  private readonly value: string;
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('change')
  private emitChange() {
    return this.internalValue;
  }

  @Emit('input')
  private emitInput() {
    return this.internalValue;
  }
  // #endregion

  // #region [ DATA ]
  public show = false;

  private readonly defaultDate: string = null;

  public internalValue: string = null;
  // #endregion

  // #region [ COMPUTED ]
  public get dateIsSet(): boolean {
    return this.internalValue !== null;
  }

  public get description(): string {
    let desc = 'Disponível em';

    if (this.internalValue) {
      desc += `: ${this.internalValue}`;
    }

    return desc;
  }

  public get modelDate(): string {
    return DateUtil.formatToYYYYMMDD(this.internalValue);
  }
  // #endregion

  // #region [ WATCHERS ]
  @Watch('value', { immediate: true })
  private valueOnChange(): void {
    this.internalValue = this.value || this.defaultDate;
  }
  // #endregion

  // #region [ LIFECYCLE ]
  // #endregion

  // #region [ METHODS ]
  public resetDate(): void {
    this.internalValue = this.defaultDate;

    this.emitInput();
    this.emitChange();
  }

  public closeMenu(): void {
    this.show = false;
  }

  private datePickerOnChange(value: string): void {
    this.internalValue = DateUtil.formatToDDMMYYYY(value);
    this.closeMenu();

    this.emitInput();
    this.emitChange();
  }
  // #endregion
}

export default ValidDateFilter;
