import { ValueIteratee } from "lodash";
import Util from "./Util";

class CurrencyUtils {
  public static readonly BRL = 'R$';
  public static readonly USD = '$';
  public static readonly EUR = '€';

  public static numberToCurrency(
    value: number | string,
  ) {
    const valueNumber = typeof value === 'number' ?
      value :
      parseFloat(value);

    const formatter = Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    });

    const currency = formatter.format(valueNumber);

    return currency;
  }
}

export default CurrencyUtils;
