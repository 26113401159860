import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  name: 'MeuPerfilTab',
  components: {},
})
class MeuPerfilTab extends Vue {
  // #region [ PROPS ]
  @Prop({ default: undefined })
  public readonly background: string;

  @Prop({ default: undefined })
  public readonly icon: string;

  @Prop({ default: false })
  public readonly loading: boolean;

  @Prop({ default: undefined })
  public readonly title: string;
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('intersection')
  private emitIntersection() {}
  // #endregion

  // #region [ DATA ]
  public intersectionOptions = {
    handler: this.emitIntersection,
    options: {
      threshold: 0.8,
    },
  };
  // #endregion

  // #region [ COMPUTED ]
  // #endregion

  // #region [ WATCHERS ]
  // #endregion

  // #region [ LIFECYCLE ]
  // #endregion

  // #region [ METHODS ]
  // #endregion
}

export default MeuPerfilTab;
