import { Vue, Component, Emit } from 'vue-property-decorator';
import * as EmailValidator from 'email-validator';
import MeuPerfilTab from '../MeuPerfilTab/MeuPerfilTab.vue';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';
import Field from '@/../bower_components/teknisavuecomponents/assets/interfaces/Field';
import ToolbarAction from '@/../bower_components/teknisavuecomponents/assets/interfaces/ToolbarAction';
import TkForm from '@/../bower_components/teknisavuecomponents/components/TkForm.vue';
import DocumentoUtil from '@/assets/ts/utils/DocumentoUtil';
import PerfilController from '@/assets/ts/controller/PerfilController';
import GeralController from '@/assets/ts/controller/GeralController';

@Component({
  name: 'DadosPessoais',
  components: {
    MeuPerfilTab,
    TkForm,
  },
})
class DadosPessoais extends Vue {
  // #region [ PROPS ]
  // #endregion

  // #region [ STORE ]
  // #endregion

  // #region [ EVENTS ]
  @Emit('intersection')
  private emitIntersection() {}
  // #endregion

  // #region [ DATA ]
  public row: any = {};
  private backup: any = {};

  private loading = {
    reload: false,
    save: false,
  };

  private fieldsData = {
    estadosCivis: {
      loading: false,
      items: [] as any[],
    },
    paises: {
      loading: false,
      items: [] as any[],
    },
    estados: {
      loading: false,
      items: [] as any[],
    },
    cidades: {
      loading: false,
      items: [] as any[],
    },
    nacionalidades: {
      loading: false,
      items: [] as any[],
    },
    grausDeInstrucao: {
      loading: false,
      items: [] as any[],
    },
  };

  public actions: ToolbarAction[] = [
    {
      name: 'save',
      label: 'Salvar',
      mode: 'edit',
      color: 'green',
      icon: 'mdi-content-save',
      onClick: this.save,
    },
    {
      name: 'cancel',
      label: 'Desfazer alterações',
      mode: 'edit',
      color: 'red',
      icon: 'mdi-close-circle',
      onClick: this.cancel,
    },
  ];
  // #endregion

  // #region [ COMPUTED ]
  public get isLoading(): boolean {
    return Util.anyTrue(Object.values(this.loading));
  }

  public get fields(): Field[] {
    return [
      {
        name: 'NMOPERADOR',
        label: 'Nome',
        type: 'text',
        size: 12,
        md: 6,
        required: true,
      },
      {
        name: 'DSEMAILOPER',
        label: 'E-mail',
        type: 'text',
        size: 12,
        md: 6,
        required: true,
        rules: [
          this.getEmailErrorMessages,
        ],
        validateOnBlur: true,
      },
      {
        name: 'NRCPFPESSOA',
        label: 'CPF',
        type: 'text',
        size: 6,
        md: 3,
        mask: '###.###.###-##',
        rules: [
          this.getCPFErrorMessages,
        ],
        validateOnBlur: true,
      },
      {
        name: 'DTNASCPESSOA',
        label: 'Data de Nascimento',
        type: 'date',
        hideDatePicker: true,
        size: 6,
        md: 3,
      },
      {
        name: 'IDSEXOPESSOA',
        label: 'Sexo',
        type: 'select',
        size: 6,
        md: 3,
        items: [
          {
            description: 'Masculino',
            value: 'M',
          },
          {
            description: 'Feminino',
            value: 'F',
          },
        ],
        descriptionField: 'description',
        valueField: 'value',
      },
      {
        name: 'CDESTACIVIL',
        label: 'Estado Civil',
        type: 'autocomplete',
        size: 6,
        md: 3,
        items: this.fieldsData.estadosCivis.items,
        loading: this.fieldsData.estadosCivis.loading,
        descriptionField: 'NMESTACIVIL',
        valueField: 'CDESTACIVIL',
      },
      {
        name: 'CDPAIS',
        label: 'País',
        type: 'autocomplete',
        size: 12,
        md: 4,
        items: this.fieldsData.paises.items,
        loading: this.fieldsData.paises.loading,
        descriptionField: 'NMPAIS',
        valueField: 'CDPAIS',
        onInput: this.paisOnInput,
      },
      {
        name: 'SGESTADO',
        label: 'Estado',
        type: 'autocomplete',
        size: 12,
        md: 4,
        items: this.fieldsData.estados.items,
        loading: this.fieldsData.estados.loading,
        readonly: this.estadoIsReadonly,
        descriptionField: 'NMESTADO',
        valueField: 'SGESTADO',
        onInput: this.estadoOnInput,
      },
      {
        name: 'CDMUNICIPIO',
        label: 'Cidade',
        type: 'autocomplete',
        size: 12,
        md: 4,
        items: this.fieldsData.cidades.items,
        loading: this.fieldsData.cidades.loading,
        readonly: this.cidadeIsReadonly,
        descriptionField: 'NMMUNICIPIO',
        valueField: 'CDMUNICIPIO',
      },
      {
        name: 'NRNACIONALID',
        label: 'Nacionalidade',
        type: 'autocomplete',
        size: 12,
        md: 4,
        items: this.fieldsData.nacionalidades.items,
        loading: this.fieldsData.nacionalidades.loading,
        descriptionField: 'DSNACIONALIDADE',
        valueField: 'NRNACIONALIDADE',
      },
      {
        name: 'NRGRAUINSTR',
        label: 'Grau de Instrução',
        type: 'autocomplete',
        size: 12,
        md: 8,
        items: this.fieldsData.grausDeInstrucao.items,
        loading: this.fieldsData.grausDeInstrucao.loading,
        descriptionField: 'NMGRAUINSTR',
        valueField: 'NRGRAUINSTR',
      },
      {
        name: 'IDPOSSUIFILHO',
        label: 'Filhos?',
        type: 'select',
        size: 12,
        md: 2,
        items: [
          {
            description: 'Sim',
            value: 'S',
          },
          {
            description: 'Não',
            value: 'N',
          },
        ],
        descriptionField: 'description',
        valueField: 'value',
      },
      {
        name: 'NRRGPESSOA',
        label: 'RG',
        type: 'text',
        size: 12,
        md: 2,
      },
      {
        name: 'CDEXRGPESSOA',
        label: 'Órgão Expedidor RG',
        type: 'text',
        size: 12,
        md: 5,
      },
      {
        name: 'DTEXRGPESSOA',
        label: 'Data de Expedição RG',
        type: 'date',
        size: 12,
        md: 3,
        hideDatePicker: true,
      },
    ] as Field[];
  }

  public get estadoIsReadonly(): boolean {
    const cdPais: string = this.row.CDPAIS;
    return !cdPais;
  }

  public get cidadeIsReadonly(): boolean {
    const cdPais: string = this.row.CDPAIS;
    const sgEstado: string = this.row.SGESTADO;

    return !cdPais || !sgEstado;
  }

  // #endregion

  // #region [ WATCHERS ]
  // #endregion

  // #region [ LIFECYCLE ]
  public async created(): Promise<void> {
    await this.reload();
    this.loadFieldsData();
  }
  // #endregion

  // #region [ METHODS ]
  private async reload(): Promise<any> {
    this.loading.reload = true;

    try {
      const data = await PerfilController.getDadosPessoais();
      this.row = data;
      this.backup = data;
    } finally {
      this.loading.reload = false;
    }
  }

  private async save(): Promise<void> {
    if (!this.validate()) {
      return;
    }

    this.loading.save = true;

    try {
      await PerfilController.salvarDadosPessoais(this.row);
      this.$toast.success('Alterações salvas com sucesso!');
      this.reload();
    } finally {
      this.loading.save = false;
    }
  }

  private validate(): boolean {
    const form: any = this.$refs.form;
    const isValid = form.validate();

    if (!isValid) {
      this.$toast.error('Preencha os campos obrigatórios!');
      return false;
    }

    if (!this.validateEmail()) {
      return false;
    }

    if (!this.validateCPF()) {
      return false;
    }

    return true;
  }

  private getEmailErrorMessages(value: string): string | boolean {
    const emailIsValid = this.validateEmail(value);

    if (emailIsValid || !value) {
      return true;
    }

    return 'E-mail inválido!';
  }

  private getCPFErrorMessages(value: string): string | boolean {
    const cpfIsValid = this.validateCPF(value);

    if (cpfIsValid || !value) {
      return true;
    }

    return 'CPF inválido!';
  }

  private validateCPF(
    value?: string,
  ): boolean {
    const cpf: string = value ?? this.row.NRCPFPESSOA;

    if (cpf) {
      return DocumentoUtil.validaCPF(cpf);
    }

    return true;
  }

  private validateEmail(
    value?: string,
  ): boolean {
    const email: string = value ?? this.row.DSEMAILOPER;
    return EmailValidator.validate(email);
  }

  private cancel(): void {
    this.$confirm({
      title: 'Desfazer alterações',
      message: 'Deseja desfazer as alterações não salvas?',
      button: {
        no: 'Cancelar',
        yes: 'Sim',
      },
      callback: (confirm: boolean) => {
        if (confirm) {
          this.row = Util.deepCopy(this.backup);
        }
      },
    });
  }

  private paisOnInput(): void {
    this.row.SGESTADO = null;
    this.row.CDMUNICIPIO = null;

    this.loadEstados();
    this.loadCidades();
  }

  private estadoOnInput(): void {
    this.row.CDMUNICIPIO = null;
    this.loadCidades();
  }

  private loadFieldsData(): void {
    this.loadPaises();
    this.loadEstados();
    this.loadCidades();
    this.loadEstadosCivis();
    this.loadNacionalidades();
    this.loadGrausDeInstrucao();
  }

  private async loadPaises(): Promise<void> {
    const fieldData = this.fieldsData.paises;
    fieldData.loading = true;

    try {
      fieldData.items = await GeralController.getPais();
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadEstados(): Promise<void> {
    const cdPais: string = this.row.CDPAIS;

    if (!cdPais) {
      return;
    }

    const fieldData = this.fieldsData.estados;
    fieldData.loading = true;

    try {
      fieldData.items = await GeralController.getEstado(cdPais);
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadCidades(): Promise<void> {
    const cdPais: string = this.row.CDPAIS;
    const sgEstado: string = this.row.SGESTADO;

    if (!cdPais || !sgEstado) {
      return;
    }

    const fieldData = this.fieldsData.cidades;
    fieldData.loading = true;

    try {
      fieldData.items = await GeralController.getMunicipio(cdPais, sgEstado);
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadEstadosCivis(): Promise<void> {
    const fieldData = this.fieldsData.estadosCivis;
    fieldData.loading = true;

    try {
      fieldData.items = await PerfilController.getEstadoCivil();
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadNacionalidades(): Promise<void> {
    const fieldData = this.fieldsData.nacionalidades;
    fieldData.loading = true;

    try {
      fieldData.items = await PerfilController.getNacionalidade();
    } finally {
      fieldData.loading = false;
    }
  }

  private async loadGrausDeInstrucao(): Promise<void> {
    const fieldData = this.fieldsData.grausDeInstrucao;
    fieldData.loading = true;

    try {
      fieldData.items = await PerfilController.getGrauInstrucao();
    } finally {
      fieldData.loading = false;
    }
  }
  // #endregion
}

export default DadosPessoais;
