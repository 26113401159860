


























































import { Vue, Component, Prop, Watch, Model, Emit } from 'vue-property-decorator';
import { VDialog } from 'vuetify/lib';

@Component({
  name: 'ConfirmationDialog',
  components: {
    'v-dialog': VDialog,
  },
})
class ConfirmationDialog extends Vue {
  // MODEL
  @Model('changeVisibilty', { type: Boolean })
  private readonly visible!: boolean;

  // PROPS
  @Prop({ required: true })
  private readonly message: string;

  @Prop({ default: true, required: false })
  private readonly showIcon: boolean;

  @Prop({ default: 'mdi-comment-question', required: false })
  private readonly icon: string;

  @Prop({ default: 'Ok', required: true })
  private readonly acceptText: string;

  @Prop({ default: 'Cancelar', required: true })
  private readonly declineText: string;

  @Prop({ default: false, required: false })
  private readonly middle: boolean;

  @Prop({ default: true})
  private readonly actionsTextCapitalize: boolean;

  @Prop({ default: true})
  private readonly dismissOnAccept: boolean;

  @Prop({ default: 400 })
  private readonly maxWidth: number;

  private textCapitalizeClass = 'text-capitalize';

  private get getKeyBoardEvents() {
    return {
      Escape: this.decline,
      Enter: this.accept,
    };
  }

  private get actionClass() {
    // Generizar gerador de classes
    return this.actionsTextCapitalize ? this.textCapitalizeClass : '';
  }

  private get cardStyle() {
    return this.middle ? 'border-radius: 10px 10px 10px 10px;' : 'border-radius: 10px 10px 0 0;';
  }

  private get component() {
    return this.middle ? 'v-dialog' : 'v-bottom-sheet';
  }

  // METHODS
  @Emit('accept')
  private accept(): any {
    if (this.dismissOnAccept) {
      this.changeVisibilty();
      return null;
    }

    return this.changeVisibilty;
  }

  @Emit('decline')
  private decline(): null {
    this.changeVisibilty();
    return null;
  }

  @Emit('changeVisibilty')
  private changeVisibilty(): boolean {
    return false;
  }

  private handleInput(ev: KeyboardEvent) {
    const keyboardAction = (this as any).getKeyBoardEvents[ev.key];
    if (keyboardAction !== undefined) {
      keyboardAction();
    }
  }
}

export default ConfirmationDialog;

