import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';
import TkForm from '@/../bower_components/teknisavuecomponents/components/TkForm.vue';
import VagaData from '@/assets/ts/interfaces/vagas/VagaData';
import Field from '@/../bower_components/teknisavuecomponents/assets/interfaces/Field';
import Util from '@/../bower_components/teknisavuecomponents/assets/utils/Util';
import CandidaturaData from '@/assets/ts/interfaces/vagas/CandidaturaData';
import VagasController from '@/assets/ts/controller/VagasController';

@Component({
  name: 'CandidatarVagaPopup',
  components: {
    TkForm,
  },
})
class CandidatarVagaPopup extends Vue {
    // #region [ PROPS ]
    @Prop({ default: null })
    private readonly vaga: VagaData;

    @Prop({ default: false })
    private readonly value: boolean;
    // #endregion

    // #region [ STORE ]
    // #endregion

    // #region [ EVENTS ]
    @Emit('candidatura')
    private emitCandidatura(): void {}

    @Emit('input')
    private emitInput(): boolean {
      return this.internalValue;
    }
    // #endregion

    // #region [ DATA ]
    public internalValue = false;

    public loading = false;

    private readonly defaultRow: CandidaturaData = {
      DSOBSERVACAOCAND: null as string,
      NRORGPROCESSO: null as string,
      NRPARCINDICACAO: null as string,
      NMPARCINDICACAO: null as string,
      NRPROCESSO: null as string,
      NMPROCESSO: null as string,
      VRPRETENSAOSALARIO: null as number,
    };

    public row: CandidaturaData = null;

    public fields: Field[] = [
      {
        name: 'NMPROCESSO',
        label: 'Vaga',
        type: 'text',
        size: 12,
        readonly: true,
      },
      {
        name: 'NMPARCINDICACAO',
        label: 'Indicação',
        type: 'text',
        size: 6,
        readonly: true,
      },
      {
        name: 'VRPRETENSAOSALARIO',
        label: 'Pretensão Salarial',
        type: 'number',
        size: 6,
        required: true,
      },
      {
        name: 'DSOBSERVACAOCAND',
        label: 'Observação',
        type: 'textarea',
        size: 12,
        rows: 3,
      },
    ];
    // #endregion

    // #region [ COMPUTED ]
    // #endregion

    // #region [ WATCHERS ]
    @Watch('value', { immediate: true })
    private valueOnChange(): void {
      this.internalValue = this.value;
    }

    @Watch('vaga', { immediate: true, deep: true })
    private vagaOnChange(): void {
      this.resetRow();
      this.setRowFromProp();
    }
    // #endregion

    // #region [ LIFECYCLE ]
    // #endregion

    // #region [ METHODS ]
    public closeDialog(): void {
      this.internalValue = false;
      this.emitInput();
    }

    private resetRow(): void {
      this.row = Util.deepCopy(this.defaultRow);
    }

    private setRowFromProp(): void {
      if (!this.vaga || !this.row) {
        return;
      }

      this.row.NRORGPROCESSO = this.vaga.NRORGPROCESSO;
      this.row.NRPROCESSO = this.vaga.NRPROCESSO;
      this.row.NMPROCESSO = this.vaga.NMPROCESSO;
      this.row.NRPARCINDICACAO = this.vaga.NRPARCINDICACAO;
      this.row.NMPARCINDICACAO = this.vaga.NMPARCINDICACAO;
    }

    public candidatarBtnOnClick(): void {
      this.candidatarVaga();
    }

    private async candidatarVaga(): Promise<void> {
      this.loading = true;

      try {
        await VagasController.candidatarVaga(this.row);

        this.$toast.success('Candidatado à vaga com sucesso!');

        this.emitCandidatura();
        this.closeDialog();
      } finally {
        this.loading = false;
      }
    }
    // #endregion
}

export default CandidatarVagaPopup;
