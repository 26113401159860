import Vue from 'vue';
import ExpectedError from '@/../bower_components/teknisavuecomponents/assets/utils/ExpectedError';
import HttpRequest from '@/../bower_components/teknisavuecomponents/assets/utils/HttpRequest';

export default class GeralController {
  public static async getPais(): Promise<any[]> {
    const route = 'getPais';

    const params = {
      requestType: 'FilterData',
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os países!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getEstado(
    cdPais: string,
  ): Promise<any[]> {
    const route = 'getEstado';

    const params = {
      requestType: 'FilterData',
      CDPAIS: cdPais,
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar os estados!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async getMunicipio(
    cdPais: string,
    sgEstado: string,
  ): Promise<any[]> {
    const route = 'getMunicipio';

    const params = {
      requestType: 'FilterData',
      CDPAIS: cdPais,
      SGESTADO: sgEstado,
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any[] = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar as cidades!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async downloadAnexo(
    nrAnexo: string,
  ): Promise<any> {
    const route = 'downloadAnexo';

    const params = {
      requestType: 'Row',
      row: {
        NRANEXO: nrAnexo,
      },
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);

      const result: any = response.data.dataset.data;
      return result;
    } catch (err) {
      let message = 'Não foi possível carregar o anexo!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }
}
