import Vue from 'vue';
import ExpectedError from '@/../bower_components/teknisavuecomponents/assets/utils/ExpectedError';
import HttpRequest from '@/../bower_components/teknisavuecomponents/assets/utils/HttpRequest';
import VagaData from '../interfaces/vagas/VagaData';
import CandidaturaData from '../interfaces/vagas/CandidaturaData';

class VagasController {
  public static async getVagas(
    nrProcesso: string = null,
  ): Promise<VagaData[]> {
    const route = 'getVagas';

    const params = {
      requestType: 'FilterData',
      NRPROCESSO: nrProcesso,
    };

    try {
      const response = await HttpRequest.get(route, params, false);
      ExpectedError.throwResponseError(response);

      const vagas: VagaData[] = response.data.dataset.data;
      return vagas;
    } catch (err: any) {
      let message = 'Não foi possível carregar as vagas!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async toggleVagaFavorita(
    nrOrgProcesso: string,
    nrProcesso: string,
  ): Promise<boolean> {
    const route = 'toggleVagaFavorita';

    const params = {
      requestType: 'Row',
      row: {
        NRORGPROCESSO: nrOrgProcesso,
        NRPROCESSO: nrProcesso,
      },
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);

      const fav: boolean = response.data.dataset.data.fav;
      return fav;
    } catch (err: any) {
      let message = 'Não foi possível marcar a vaga como favorita!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async candidatarVaga(
    row: CandidaturaData,
  ): Promise<void> {
    const route = 'candidatarVaga';

    const params = {
      requestType: 'Row',
      row,
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err: any) {
      let message = 'Não foi possível candidatar à vaga!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }

  public static async enviarIndicacao(
    nrProcesso: string,
    email: string,
  ): Promise<void> {
    const route = 'enviarIndicacao';

    const params = {
      requestType: 'Row',
      row: {
        NRPROCESSO: nrProcesso,
        DSEMAILOPER: email,
      },
    };

    try {
      const response = await HttpRequest.post(route, params, false);
      ExpectedError.throwResponseError(response);
    } catch (err: any) {
      let message = 'Não foi possível indicar a vaga!';

      if (err instanceof Error) {
        message = ExpectedError.getErrorMessage(err, message);
      }

      Vue.$toast.error(message);
      throw err;
    }
  }
}

export default VagasController;
